.html-include {
    &.pw-vergessen {
        max-width: 650px;
        margin: 0 auto;

        .box {
            font-size: 1.6rem;
        }

        button {
            margin: 1.5rem 0 0 0 !important;
            padding: 1rem 6rem !important;
            font-size: 1.6rem !important;
            float: left;
        }

        input {
            background: none;
            border: 0;
            border-bottom: 2px solid #333 !important;
            padding: 0;
            width: 220px !important;
            font-size: 1.4rem;
        }
    }

    &.american-sports {
        section {
            outline: none;
        }

        h3 {
            margin: 2rem 0 1rem 0.5rem;
            border-bottom: 1px solid #dedede;
            padding: 0 0 0.5rem 0;
            font-size: 1.8rem;
        }

        + .alle-artikel {
            margin: 4rem 0.5rem -2rem 0.5rem;
            padding: 0 0 0.5rem 0;
            border-bottom: 1px solid #dedede;
        }

        .ligen {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            background: white;
            margin: 1.5rem 0.5rem 0 0.5rem;

            > div {
                padding: 1rem 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            img {
                height: 10rem;
                max-width: unset;
                padding: 1rem;
            }
        }

        nav {
            .lvl_1 {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0;

                li {
                    list-style-type: none;
                    padding: 1rem;
                    background-size: 5rem !important;
                    flex: 0 calc(100% / 7 - 1rem);
                    margin: 0.5rem;
                    box-sizing: border-box;
                    height: 14rem;

                    &.head {
                        background: $color-1;
                        color: white;
                        font-size: 3rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    a {
                        display: block;
                        text-align: center;
                        font-size: 1.3rem;
                        width: 100%;
                        height: 100%;
                    }

                    // Logos
                    // MLB 663-692 / NBA 693-721 / NFL 722-753 / NHL 754-783
                    @for $i from 663 through 783 {
                        &.men_azu_#{$i} {
                            background: white url(#{$cdn}/#{$i}.svg) 50% 70% no-repeat;
                        }
                    }

                    &.men_azu_881 {
                        background: white url(#{$cdn}/881.svg) bottom no-repeat;
                    }

                    .men_azu_1213 {
                        background: white url(#{$cdn}/1213.svg) bottom no-repeat;
                    }

                    .men_azu_1209 {
                        background: white url(#{$cdn}/1209.svg) bottom no-repeat;
                    }
                }
            }
        }
    }
}

@include breakpoint("s") {
    .html-include {
        &.american-sports {
            h3 {
            }

            .ligen {
                > div {
                    flex: 0 1 50%;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                }

                img {
                }
            }

            nav {
                .lvl_1 {
                    li {
                        flex: 0 calc(100% / 3 - 1rem);

                        a {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("xs") {
    .html-include {
        &.american-sports {
            h3 {
            }

            .ligen {
                > div {
                }

                img {
                }
            }

            nav {
                .lvl_1 {
                    li {
                        a {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}
