$cdn: "https://cdn.capuniverse.com";
$font-cdn: "https://css.capuniverse.com/f.php?f=";

// helpers/vars/mixins -> Müssen zuerst importiert werden
@import "vars/breakpoint";
@import "vars/color";
@import "vars/font";
@import "vars/icon";

@import "vars/button";
@import "vars/headline";

// mixins
@import "mixins/breakpoint";
@import "mixins/print";

// base
@import "base/animate";
@import "base/base";
@import "base/font";
@import "base/headline";

// components
@import "components/banner";
@import "components/benefit";
@import "components/breadcrumb-filter";
@import "components/button";
@import "components/cross-selling";
@import "components/registration";
@import "components/info";
@import "components/marken";
@import "components/menu";
@import "components/powersuche";
@import "components/umfrage-bestellabschluss";
@import "components/user";
@import "components/cookie_v3";
@import "components/push-notifications";
@import "components/mfp_main";
@import "components/seo";

// layout
@import "layout/artikeldetail";
@import "layout/bestelluebersicht";
@import "layout/footer";
@import "layout/header";
@import "layout/service";
@import "layout/startseite";
@import "layout/suchergebnis";
@import "layout/wk";

// pages
@import "pages/fehler";
@import "pages/html-includes";
@import "components/shopseiten";

// gridlex
@import "gridlex/gridlex";

.white-popup {
    position: relative;
    background: #fff;
    padding: 20px;
    width: auto;
    max-width: 700px;
    margin: 20px auto;
    border-radius: 5px;
}

$base-font-size: 5px;

$switch-width: 9em;
$switch-size: 4.6em;
$switch-space: 0.2em;

$switch-off-padding: 0em;
$switch-on-padding: $switch-size - $switch-space;
$switch-button-size: $switch-size - $switch-space * 2;

$switch-off-border: #eee;
$switch-on-color: $color-3;
$switch-off-color: #eee;
$switch-toggle-color: #fff;

$switch-animation-bounce-distance: 0.4em;

input.switch {
    font-size: $base-font-size;
    position: relative;
    display: inline-block;
    visibility: visible !important;
    appearance: none;
    width: $switch-width;
    height: $switch-size;
    line-height: $switch-size;
    border-radius: $switch-size / 2;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 0 $switch-space $switch-off-border;
    outline: 1px solid transparent !important;
    cursor: pointer;
    border: none;
    background: $switch-off-color !important;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-appearance: caret;
    transition:
        box-shadow 0.3s ease-in-out,
        padding 0.25s ease-in-out;
    transition-delay: 0.1s, 0s;

    &:checked {
        transition-delay: 0s, 0s;
        box-shadow: inset 0 0 0 ($switch-size / 1.75) $switch-on-color !important;
        padding-left: $switch-on-padding;
    }

    /* Animations if supported */
    &.hit {
        animation: slide-off 0.35s ease both;
    }

    &.hit:checked {
        animation: slide-on 0.35s ease both 0.05s;
    }

    &::before,
    &::after {
        content: "";
    }

    &::after {
        /* Increases hit area */
        $size-adjust: -($switch-button-size / 3);

        position: absolute;
        top: $size-adjust;
        left: $size-adjust;
        bottom: $size-adjust;
        right: $size-adjust;
    }

    &::before {
        display: inline-block;
        height: $switch-button-size;
        width: $switch-button-size;
        margin: $switch-space 0 0 $switch-space;
        background-color: $switch-toggle-color;
        border-radius: $switch-button-size / 2;
        box-shadow:
            0 $switch-space ($switch-space * 2) 0 rgba(0, 0, 0, 0.2),
            0 0 ($switch-space / 2) ($switch-space / 2) rgba(0, 0, 0, 0.1);
        transition: all 0.1s ease 0.1s;
    }

    &:active::before {
        transition: all 0.1s ease 0.05s;
    }

    &.touch:hover::before {
        transition: all 0s ease 0s;
    }

    &:active::before,
    &.touch:hover::before {
        width: $switch-button-size + $switch-space * 4;
    }

    &:active:checked::before,
    &.touch:hover:checked::before {
        margin-left: -($switch-space * 3);
    }
}

@keyframes slide-on {
    0% {
        padding-left: $switch-off-padding;
    }

    60% {
        padding-left: $switch-on-padding + $switch-animation-bounce-distance;
    }

    100% {
        padding-left: $switch-on-padding;
    }
}

@keyframes slide-off {
    0% {
        padding-left: $switch-on-padding;
        text-indent: 0;
    }

    60% {
        padding-left: $switch-off-padding;
        text-indent: -$switch-animation-bounce-distance;
    }

    100% {
        padding-left: $switch-off-padding;
        text-indent: 0;
    }
}
