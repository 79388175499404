#shopMain {
    .startseite {
        margin: 0 auto;

        h3 {
            margin: 3rem 1rem 1rem 0;
        }

        > .wrapper_her {
            margin: 2rem 1rem;
        }

        .banner-container {
            margin: 0 0.5rem;

            > a {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    object-fit: cover;
                }

                .unterteil {
                    background: $color-1;
                    width: 100%;
                    height: 7rem;
                    padding: 1.6rem;
                    font-size: 2.7rem;
                    font-weight: 700;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    color: $color-2;
                    justify-content: space-around;
                    margin-top: -3px;

                    .marke {
                        text-transform: uppercase;
                        font-size: 2rem;
                    }

                    .beschreibung {
                        font-size: 1.6rem;
                    }

                    > button {
                        position: absolute;
                        right: 3rem;
                        bottom: 4.8rem;
                        border-radius: 50%;
                        height: 7rem;
                        width: 7rem;
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.7);
                    }
                }

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 120px;
                    height: 120px;
                    padding: 0;

                    &:before {
                        content: "";
                        width: 50px;
                        height: 50px;
                        background-position: center;
                    }

                    &.iriedaily {
                        &:before {
                            background: map-get($icons, "iriedaily") 0 (center / 50px) no-repeat;
                        }
                    }

                    &.brixton {
                        &:before {
                            background: map-get($icons, "brixton") 0 (center / 50px) no-repeat;
                        }
                    }
                }
            }
        }

        .image-menu {
            display: flex;
            flex-wrap: wrap;

            > a {
                flex: 0 calc(100% / 3 - 2rem);
                position: relative;
                margin: 1rem;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 120px;
                    height: 120px;
                    padding: 0;

                    &:before {
                        content: "";
                        width: 50px;
                        height: 50px;
                        background-position: center;
                    }
                }

                &.pic-1 {
                    button:before {
                        background: map-get($icons, "caps") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-2 {
                    button:before {
                        background: map-get($icons, "fitted-caps") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-3 {
                    button:before {
                        background: map-get($icons, "trucker-caps") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-4 {
                    button:before {
                        background: map-get($icons, "5-panel-caps") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-5 {
                    button:before {
                        background: map-get($icons, "snapback-caps") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-6 {
                    button:before {
                        background: map-get($icons, "muetzen") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-7 {
                    button:before {
                        background: map-get($icons, "huete") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-8 {
                    button:before {
                        background: map-get($icons, "neuheiten") 0 0 / 50px no-repeat;
                    }
                }

                &.pic-9 {
                    button:before {
                        background: map-get($icons, "deals") 0 0 / 50px no-repeat;
                    }
                }

                > .unterteil {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: $color-1;
                    width: 100%;
                    height: 7rem;
                    display: flex;
                    align-items: center;
                    padding: 0 0 0 2rem;
                    font-size: 2.7rem;
                    font-weight: 700;
                    box-sizing: border-box;

                    > .titel {
                        color: $color-2;
                    }

                    > button {
                        position: absolute;
                        right: 3rem;
                        bottom: 3.6rem;
                        border-radius: 50%;
                        height: 7rem;
                        width: 7rem;
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.7);
                    }
                }
            }
        }

        .social-box {
            display: flex;
            margin: 2rem 1rem;

            > .youtube-box {
                flex: 66%;
                height: auto;
                margin-right: 1rem;

                > div {
                    iframe {
                        width: 100%;
                        height: 538px;
                    }
                }
            }

            > .instagram-box {
                margin-left: 1rem;
                flex: 32%;
            }
        }

        .benefits {
            display: flex;
            align-items: baseline;
            margin: 2rem 1rem;
            padding: 7rem 0;
            background: $color-2;

            .lieferzeit button:before {
                background: map-get($icons, "lieferzeit") 0 0 / 70px no-repeat;
            }

            .versand button:before {
                background: map-get($icons, "kostenloser-versand") 0 0 / 70px no-repeat;
            }

            .rueckversand button:before {
                background: map-get($icons, "kostenloser-rueckversand") 0 0 / 70px no-repeat;
            }

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex: calc(100% / 3);
                text-align: center;

                > button {
                    border-radius: 50%;
                    height: 11rem;
                    width: 11rem;
                    padding: 0;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        content: "";
                        cursor: pointer;
                        width: 70px;
                        height: 70px;
                    }
                }

                > span {
                    margin: 1rem 0 0 0;
                    font-size: 1.8rem;
                }
            }
        }

        .banner.unten {
            padding: 2rem 0;
            column-count: 3;
            column-gap: 5rem;
            font-size: 1.4rem;
            font-weight: 300;
            background: transparent;

            p {
                font-size: 1.6rem;
            }
        }
    }
}

@include breakpoint("xl") {
    #shopMain {
        .startseite {
            .social-box {
                > .youtube-box {
                    > div {
                        iframe {
                            height: 445px;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("l") {
    #shopMain {
        .startseite {
            .social-box {
                > .youtube-box {
                    > div {
                        iframe {
                            height: 353px;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("l", "min") {
    #shopMain {
        .startseite {
            .banner-container {
                > a {
                    img {
                        height: 550px;
                    }
                }
            }
        }
    }
}

@include breakpoint("m") {
    #shopMain {
        .startseite {
            .image-menu {
                > a {
                    flex: 0 calc(100% / 2 - 2rem);

                    > .unterteil {
                        height: 50px;

                        > .titel {
                            font-size: 2rem;
                        }

                        > button {
                            bottom: 2rem;
                            height: 6rem;
                            width: 6rem;
                        }
                    }
                }
            }

            .social-box {
                flex-wrap: wrap;

                > .youtube-box {
                    flex: 1 0 100%;
                    margin: 1rem 0;

                    > div {
                        iframe {
                        }
                    }
                }

                > .instagram-box {
                    flex: 1 0 100%;
                    margin: 1rem 0;
                }
            }

            .banner.unten {
                column-count: 2;
            }
        }
    }
}

@include breakpoint("s") {
    #shopMain {
        .startseite {
            .banner-container {
                margin: 0 -0.5rem;

                > a {
                    img {
                        height: auto;
                    }
                }
            }

            .wrapper_her {
                margin: 0;
            }

            .list_se {
                .artikel {
                    width: 276px;
                    margin: 2px;
                }
            }

            .image-menu {
                > a {
                    flex: 0 100%;
                    margin: 0 0 1px 0;

                    img {
                        display: none;
                    }

                    .unterteil {
                        position: relative;
                        padding: 10px;
                        height: auto;

                        .titel {
                            font-weight: 100;
                            padding: 0 0 0 10px;
                            font-size: 1.5rem;
                        }
                    }

                    button {
                        display: none;
                    }
                }
            }
        }
    }
}

@include breakpoint("xs") {
    #shopMain {
        .startseite {
            margin: -2rem auto;

            .social-box {
                > .youtube-box {
                    > div {
                        iframe {
                            height: 250px;
                        }
                    }
                }

                > .instagram-box {
                }
            }

            .benefits {
                display: flex;
                padding: 2rem 0;

                > div {
                    button {
                        width: 7rem;
                        height: 7rem;

                        &:before {
                            width: 5rem;
                            height: 5rem;
                        }
                    }

                    span {
                        font-size: 1.3rem;
                    }
                }

                .lieferzeit button:before {
                    background: map-get($icons, "lieferzeit") 0 0 / 5rem no-repeat;
                }

                .versand button:before {
                    background: map-get($icons, "kostenloser-versand") 0 0 / 5rem no-repeat;
                }

                .rueckversand button:before {
                    background: map-get($icons, "kostenloser-rueckversand") 0 0 / 5rem no-repeat;
                }
            }

            .banner.unten {
                column-count: 1;
            }
        }
    }
}

@media (max-width: 1024px) {
    [class*="md-hidden"] {
        display: none !important;
    }
}

@include breakpoint("s", "min") {
    #shopMain {
        .startseite {
            h3 {
                &.kategorien {
                    display: none;
                }
            }
        }
    }
}
