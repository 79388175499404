$cdn: "https://cdn.capuniverse.com";

.shop_tab_mitte {
    width: auto !important;
}

#shopLayer {
    &.powersuche-offen {
        .top_bread-sort {
            display: none;
        }
    }
}

#ps-menu {
    display: none;
    flex-wrap: wrap;
    position: unset;
    background: transparent;
    width: unset;
    padding: 0;
    overflow-y: inherit;
    padding-top: 10px;

    * {
        font-size: initial !important;
    }

    .dd_list {
        .se_li_such {
            height: 54px !important;

            > input {
                height: 42px;
            }
        }

        .see_multi_label {
            width: 100%;
            margin-top: 0;
            line-height: 43px;
        }

        > ul {
            margin-bottom: 10px;
        }
    }

    ul {
        display: block;
        float: left;
    }

    .see_alle_dd {
        display: block;

        > ul:not(.ausgewaehlt) {
            background-color: white;
        }
    }

    #ps-filter-reset {
        box-sizing: border-box;
    }

    &.toggle-filter {
        display: flex;
    }

    .total {
        padding: 12px 16px;
        margin: 8px;
        align-self: center;
        width: 204px;
        display: none;
    }

    h3 {
        background: #ffffff;
        padding: 12px 16px;
        border-radius: 5px;
        align-self: center;
        width: 204px;
        margin: 8px;

        &:after {
            content: " ";
            background: url(#{$cdn}/dropdown-arrow.svg) no-repeat (50% / contain);
            height: 12px;
            width: 12px;
            float: right;
            transition: transform 0.5s;
            padding-top: 3px;
        }
    }

    * {
        transform: unset;
    }

    .ps-filter-container {
        cursor: pointer;

        &.mitAusgwewaehltemElement {
            h3 {
                background: $color-3;
                color: #ffffff;

                &:after {
                    background: url(#{$cdn}/dropdown-arrow-w.svg) no-repeat (50% / contain);
                }
            }

            li {
                color: #ffffff !important;
            }
        }

        &.open {
            ul {
                display: block;
                background: $color-3;
                padding: 10px;
                border-radius: 5px;
                position: absolute;
                width: 216px;
                z-index: 1;
                max-height: 350px;
                overflow-y: auto;
                margin: -7px 8px;
            }

            li {
                color: #ffffff;
                padding: 5px 0;
            }
        }
    }

    #ps-anti-filter {
        width: 100%;
        order: -1;
        display: flex;
        margin: 0 8px;

        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;

            li {
                padding: 0 0 0 10px;
                align-items: center;
                display: flex;
                cursor: pointer;
                font-size: 1.2rem !important;

                &:after {
                    content: "";
                    display: block;
                    background: url(#{$cdn}/icon_close.svg) no-repeat center;
                    background-size: 10px 10px !important;
                    height: 25px;
                    padding: 0 0 0 25px;
                    margin: 0 0.5rem 0 0;
                }

                .see_multi_label {
                    font-size: 1.2rem !important;
                }

                .see_multi_checkbox {
                    display: none;
                }
            }
        }

        .see_li {
            &:hover {
                background: none;
            }

            &.active label:before {
                content: none;
                background: none;
            }

            label {
                margin: 0;
            }
        }
    }

    #ps-filter-reset {
        box-shadow: none;
        margin: 0 8px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        font-size: 1.2rem !important;

        &:after {
            content: "";
            display: block;
            background: url(#{$cdn}/icon_close.svg) no-repeat center;
            background-size: 10px 10px !important;
            height: 25px;
            padding: 0 0 0 1.5rem;
            margin: 0 0.5rem 0 0;
        }

        &:before {
            content: "alle Filter löschen";
            color: #737982;
            cursor: pointer;
        }
    }
}

#ps-suchleiste {
    display: flex;
    height: 4rem;
    position: relative;
    top: 0 !important;
    z-index: 1;
    outline: 0;

    * {
        font-size: inherit !important;
    }

    input::-ms-clear {
        display: none;
    }

    #suchStr {
        width: 100%;
        background: transparent;
        font-size: 1.6em !important;
        border: none;

        &::placeholder {
            color: $color-1-1;
        }
    }

    #ps-filter-toggle {
        display: none;

        &.not-empty {
            display: flex;
        }
    }

    #ps-suche-schliessen {
        position: unset;
        background-size: auto;
        order: 3;
        height: auto;
        margin: 0 10px;
    }

    > * {
        box-sizing: border-box;
    }

    &.hatFiltermoeglichkeiten {
        #ps-filter-toggle {
            padding: 0;
            margin: 0 8px;
            align-self: center;
            box-shadow: none;
            min-width: 180px;
            align-items: center;
            justify-content: flex-end;
            order: 2;
            position: relative;
            font-size: 1.2rem;
            font-weight: 700;
            color: #a3abb8 !important;

            &:after {
                content: "";
                display: block;
                transition: background 0.5s;
                margin-left: 15px;
                width: 40px;
                height: 22px;
                box-sizing: border-box;
                padding: 2px;
                background: $color-3;
                border-radius: 20px;
            }

            &:before {
                transition: margin 0.5s;
                content: "";
                display: block;
                float: left;
                height: 18px;
                width: 18px;
                background: #fff;
                border-radius: 18px;
                position: absolute;
                right: 2px;
                transition: right 200ms ease-in-out;
            }

            &:not(.filter-open) {
                &:before {
                    right: 20px;
                    transition: right 200ms ease-in-out;
                }

                &:after {
                    background: #a3abb8;
                }
            }
        }
    }
}

#ps-weiteres {
    margin: -10px 8px 10px 8px;
    background: #f9f9f9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    z-index: 1;
    top: 0 !important;
    text-transform: capitalize;

    #ps-vorschlage {
        margin: 0;
        background: transparent;
        padding: 10px 0;
        list-style-type: none;

        &.hide-vorschlaege {
            display: none !important;
        }

        > li {
            padding: 5px 0 5px 22px;
            font-size: 1.3rem;

            &.active,
            &.active * {
                color: $color-3;
            }

            &.gefundene-kategorie a:before {
                content: "Kategorie: ";
                display: inline;
                font-weight: 700;
            }
        }
    }

    #ps-infos {
        display: none !important;
    }
}

#ps-artikel {
    margin: 20px auto;
    justify-content: center;

    .unimportant {
        display: none !important;
    }

    + #ps-mehr-desktop {
        opacity: 0;
    }
}

/***
    Filter
***/

.hideoverflow {
    position: unset !important;
    overflow-y: unset !important;
    height: unset !important;
    width: unset !important;
}

.powersuche-offen {
    #ps-menu {
        display: none;

        &.open {
            display: flex;
        }

        #ps-anti-filter {
            > ul {
                display: flex;
                list-style-type: none;
                flex-wrap: wrap;

                label {
                    width: 100%;
                    margin-right: 0.5rem;
                }
            }
        }

        .see_alle_dd {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            box-sizing: border-box;
            margin: 10px 0;
            width: 100%;

            #ps-filter-reset {
                margin-bottom: 0;
                box-shadow: none;
                width: 100%;

                &:after {
                    content: "";
                    background-color: transparent;
                }
            }

            > ul {
                list-style-type: none;
                flex: 0 1 260px;
                background: #fff;
                margin: 10px;
                box-sizing: border-box;
                cursor: pointer;
                padding: 0.8rem 1.2rem;
                font-size: 1.4rem;
                font-weight: 400;
                position: relative;
                height: 35px;
                z-index: 1;

                &:nth-child(1) {
                    z-index: 20;
                }

                &:nth-child(2) {
                    z-index: 19;
                }

                &:nth-child(3) {
                    z-index: 18;
                }

                &:nth-child(4) {
                    z-index: 17;
                }

                &:nth-child(5) {
                    z-index: 16;
                }

                &:nth-child(6) {
                    z-index: 15;
                }

                &:nth-child(7) {
                    z-index: 14;
                }

                &:nth-child(8) {
                    z-index: 13;
                }

                > li {
                    font-size: 1.2rem;

                    &:not(.active) {
                        .dd_list {
                            display: none !important;
                        }
                    }

                    &.active {
                        .dd_list {
                            background: #fff;
                            margin: 10px 0 0 -12px;
                            padding: 0.8rem 1.2rem;
                            z-index: 4;
                            width: 100%;
                        }
                    }
                }

                button {
                    border-radius: 0;
                }

                .dd_list {
                    padding: 0;

                    button {
                        padding: 1rem !important;
                        font-size: 1.6rem !important;
                        margin-bottom: 0;
                        width: 90%;
                    }

                    > ul {
                        list-style-type: none;
                        padding: 0;
                        max-height: 245px;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        width: 100%;

                        .se_li_such {
                            margin: 15px 0 10px 0;
                            border-bottom: 1px solid #323232;
                            height: auto !important;

                            > input {
                                outline: none;
                                border: none;
                                font-size: 1.6rem;
                                height: auto;
                            }

                            .leere_input {
                                display: none;
                            }
                        }

                        > li {
                            list-style-type: none;
                            padding: 0.4rem 0;

                            .see_multi_label {
                                display: flex;
                                align-items: center;
                                font-size: 1.6rem;
                                line-height: unset;

                                .see_multi_checkbox {
                                    background: 0 0;
                                    position: relative;
                                    padding: 0;
                                    margin: 0 1.5rem 0 0;

                                    &.changed {
                                        &:before {
                                            background: #ad1457;
                                            border: 2px solid #424242;
                                        }
                                    }

                                    &:before {
                                        content: " ";
                                        display: block;
                                        width: 15px;
                                        height: 15px;
                                        border: 2px solid #ad1457;
                                        position: relative;
                                        top: -4px;
                                        background: #fff;
                                        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
                }

                a {
                    font-size: 1.6rem;
                }

                li div:first-child {
                    position: relative;

                    &:after {
                        content: " ";
                        display: block;
                        background: url(https://cdn.capuniverse.com/filter-arrow.svg) no-repeat (50% / contain);
                        position: absolute;
                        top: -0.6rem;
                        right: 0;
                        background-size: 2.5rem;
                        height: 3.5rem;
                        width: 3rem;
                    }
                }
            }
        }
    }

    #ps-menu-hintergrund {
        display: none;
    }
}

#shopMitte2 {
    margin: 0 !important;

    .shop_tab_mitte {
        width: initial !important;
    }
}

#ps-weiteres {
    margin-top: -1px;
    top: 0 !important;
    position: relative;

    #ps-infos {
        display: none !important;
    }

    &.verstecken {
        display: block;
        position: relative;
        z-index: 1;
        top: 0 !important;
    }
}

@include breakpoint("m") {
    div#ps-suchleiste,
    div#ps-weiteres {
        width: auto;
    }

    #ps-weiteres * {
        font-size: inherit;
    }
}

@include breakpoint("xs") {
    .powersuche-offen {
        #ps-menu {
            .see_alle_dd {
                > ul {
                    flex: 0 1 100%;
                }
            }
        }
    }
}
