.html_herst_liste {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > .alle_marken {
        background: $color-1;
        color: $color-4;
        font-size: 3rem;
        padding: 1rem;
        margin: 0.3rem;
        font-weight: 700;
    }

    > .html_herst_liste_box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 40px;
        text-align: center;
        padding: 1rem 0.5rem;
        margin: 0.3rem;
        background: $color-2;
        font-size: 2rem;
        height: 3.6rem;
        color: $color-3;
        font-weight: 700;
    }
}

.wrapper_her {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    > .alle_her,
    > .he_first,
    > .mehr_marken {
        flex: 0 calc(100% / 6 - 0.2rem);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.1rem;
        background: $color-2;
        text-align: center;
        height: 80px;

        img {
            height: 50px;
            max-width: 130px;
        }
    }

    > .he_first {
        color: $color-3;
        font-weight: 700;
        font-size: 3rem;

        &:empty {
            display: none;
        }

        &:focus {
            outline: 0;
        }
    }

    > .mehr_marken {
        flex: 1;

        > a {
            color: $color-3;
            font-weight: 700;
            font-size: 1.5rem;
        }
    }
}

@include breakpoint("s") {
    .wrapper_her {
        > .alle_her,
        > .he_first {
            flex: 0 calc(33.33% - 0.2rem);

            img {
                max-width: 100px;
            }
        }
    }

    .startseite {
        .wrapper_her {
            @for $i from 6 through 12 {
                .her#{$i} {
                    display: none;
                }
            }
        }
    }
}
