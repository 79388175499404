.umfrage {
    .box {
        border: 3px solid $color-3;

        .frage,
        .sonstiges,
        .senden {
            margin: 10px 0;
            border-radius: 0 !important;
        }

        .oberthema {
            &:after {
                content: none;
            }

            &.active {
                ~ .subitem,
                ~ .sonstiges {
                    display: block;
                    max-width: calc(100% - 20px);
                    margin-top: 10px;
                    border: none;
                }

                ~ .sonstiges {
                    padding: 10px;
                    margin: 10px;
                    box-sizing: border-box;
                }
            }
        }

        .item {
            background: $color-3;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: calc(100%);
            padding: 10px;
            margin: 10px 0;
            cursor: pointer;

            > input {
                position: relative;
                display: block;
                height: 100%;
                width: 100%;
                cursor: auto;
                visibility: unset;
                border-radius: 0 !important;
                margin: 0;
                -webkit-appearance: none;
                font-size: 12px;

                &.active {
                    &:after {
                        content: " ";
                        background: url(#{$cdn}/check_weiss.svg) no-repeat 0 (0 / contain);
                        position: absolute;
                        display: block;
                        right: 10px;
                        top: 2px;
                        width: 20px;
                        height: 20px;
                    }
                }

                &:focus {
                    border: none !important;
                }
            }

            label {
                line-height: unset;
                color: #ffffff;
                text-align: center;
                width: 100%;
                cursor: pointer;
            }

            &.subitem {
                padding: 0 10px;

                > input[type="checkbox"] {
                    display: none;

                    + label {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        &:before {
                            content: "";
                            display: block;
                            float: left;
                            width: 12px;
                            height: 12px;
                            border: 2px solid $color-3;
                            background-color: #fff;
                            margin-right: 8px;
                        }
                    }

                    &:checked + label {
                        &:before {
                            box-shadow: inset 0px 0px 0px 2px #fff;
                            background-color: $color-3;
                        }
                    }
                }

                label {
                    margin: 0;
                }
            }

            &.subitem,
            .sonstiges {
                display: none;
            }
        }

        .sonstiges {
            width: calc(100% - 20px);
            height: 35px;
            font-size: 16px;
            color: grey;
            padding: 3px 10px;
            border: 1px solid #adadad;
        }
    }

    .umfrage-fertig {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: $color-3;
    }
}

@media (max-width: 576px) {
    .umfrage {
        .box {
            .item {
                // max-width: calc(100% - 20px);
            }
        }
    }
}
