.shopseiten-start {
    max-width: 1008px;
    margin: 0 auto;

    .box {
        background: #fff;
        padding: 16px;
        border-radius: 5px;
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }

    a {
        color: $color-3;
    }

    nav {
        display: flex;
        justify-content: space-evenly;
        margin: 20px 0 30px 0;

        a {
            font-size: 25px;

            &:hover,
            &:active,
            &:focus {
                color: $color-1-1;
            }
        }
    }

    .se.list_se {
        width: 100%;
    }

    #welten,
    #blog,
    #ratgeber,
    #blog-archive,
    #ratgeber-lexikon {
        padding: 0 8px;
        margin: 0 0 60px 0;

        > h1,
        > h2 {
            font-size: 20px;
            margin: 20px 0 10px 0;
        }
    }

    #ratgeber-lexikon div {
        display: grid;
        gap: 1vw;
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));

        a {
            padding: 12px;
            background: #fff;
            display: inline-block;
            border-radius: 5px;
            font-size: 20px;
            text-align: center;
            transition: all 300ms ease-in-out;
            width: 100%;
            box-sizing: border-box;
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
        }
    }

    #blog .box img {
        display: block !important;
    }

    #blog {
        .box-wrapper {
            .small {
                display: flex;
                width: 100%;

                .article p:first-child {
                    margin: 20px 0 10px 0;
                }

                .article img,
                .article p:last-child {
                    display: none !important;
                }
            }

            .big {
                display: flex;
                flex-wrap: wrap;
            }

            img[src=""] {
                display: none !important;
            }
        }

        > div > h2 {
            margin: 60px 0 0 0;
        }
    }

    .box-wrapper .article div.blog-tag + div {
        margin: 20px 0 0 0;
    }

    #welten {
        .box-wrapper {
            h3.box {
                display: block;
                flex: 1 1 100%;
                max-width: none;
                background: none;
                box-shadow: none;
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 18px;
            }

            .box.big {
                min-width: calc(50% - 16px);
            }
        }
    }

    .box-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;

        .box {
            margin: 8px;
            overflow: hidden;
            padding: 0;
            order: 4;
            max-width: calc(33.33% - 16px);
            transition: box-shadow 200ms ease-in-out;

            span {
                font-size: 14px;
            }

            &.sommer,
            &.winter &.fall,
            &.spring {
                min-width: calc(33.33% - 16px);
            }

            &.neu,
            &.top {
                min-width: calc(50% - 16px);
                order: 10;
            }

            &:not(.summer):not(.winter):not(.spring):not(.fall):not(.top):not(.neu) {
                min-width: calc(33.33% - 16px);

                img {
                    display: none;
                }
            }

            &:hover {
                box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.3);
                transition: box-shadow 200ms ease-in-out;
            }

            a {
                > div {
                    padding: 15px 20px;
                }
            }

            img {
                width: 100%;
            }

            h2 {
                font-size: 20px;
                margin: 0 0 5px 0;
            }

            &.article {
                position: relative;

                .blog-intro-text {
                    font-size: 14px;
                }

                h2 {
                    margin: 8px 0;
                }

                .blog-tag {
                    position: absolute;
                    background: $color-3;
                    right: 0;
                    color: #fff;
                    border-bottom-left-radius: 5px;
                    padding: 10px;
                    min-width: 81px;
                    box-sizing: border-box;
                    text-align: center;

                    &:empty {
                        display: none;
                    }
                }

                .date {
                    margin: 0 0 10px 0;
                }

                div + div {
                    height: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                p:first-child {
                    font-size: 20px;
                    margin: auto 0;
                }
            }
        }
    }

    @for $i from 1 through 3 {
        .blog_featured .box-wrapper .box:first-child:nth-last-child(#{$i}),
        .blog_featured .box-wrapper .box:first-child:nth-last-child(#{$i}) ~ .box {
            width: calc(100% / #{$i} - 16px) !important;
            max-width: none !important;
        }
    }

    .blog_featured .box-wrapper .box:first-child:nth-last-child(4),
    .blog_featured .box-wrapper .box:first-child:nth-last-child(4) ~ .box {
        width: calc(100% / 2 - 16px) !important;
        max-width: none !important;
    }

    #welten {
        .box-wrapper {
            &.col-2 {
                .box {
                    min-width: calc(50% - 16px);
                }
            }
        }

        h3 {
            margin: 40px 0 0 0;
        }
    }

    #ratgeber .box {
        max-width: calc(25% - 16px);
        text-align: center;
        min-width: 0;

        img {
            display: block !important;
        }

        h2 {
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.shopseite.blog {
    .fb.text2 {
        background: transparent;
        box-shadow: none;
        padding: 0px 126px !important;
    }

    .info-wrapper {
        .info-inh {
            div {
                background: #e5e5e5 !important;
            }
        }

        .info-text {
            background: #fff;

            section {
                padding: 0 16px;

                figure {
                    width: auto;
                }
            }

            ul {
                margin-left: 15px;

                li {
                    padding: 0 5px 10px 0;
                }
            }
        }
    }
}

@media (max-width: 1179px) {
    .shopseite.blog .fb.text2 {
        padding: 0 30px !important;
    }
}

@media (max-width: 1024px) {
    .shopseiten-start #ratgeber .box h2 {
        font-size: 14px;
    }
}

@media (max-width: 840px) {
    .shopseite.blog .fb.text2 {
        padding: 0 15px !important;
    }
}

@media (max-width: 760px) {
    .shopseiten-start {
        .blog_featured .box-wrapper .box:first-child:nth-last-child(2),
        .blog_featured .box-wrapper .box:first-child:nth-last-child(2) ~ .box {
            width: calc(100% - 16px) !important;
            max-width: none !important;
        }

        .welten {
            .welt {
                &.big,
                &.small {
                    max-width: calc(100% - 16px);
                }
            }
        }

        .box-wrapper {
            .box {
                min-width: calc(50% - 16px) !important;
            }

            &.winter {
                .winter,
                .fall_winter {
                    min-width: calc(100% - 16px) !important;
                }
            }

            &.fall {
                .fall,
                .fall_winter {
                    min-width: calc(100% - 16px) !important;
                }
            }

            &.summer {
                .summer,
                .spring_summer {
                    min-width: calc(100% - 16px) !important;
                }
            }

            &.spring {
                .spring,
                .spring_summer {
                    min-width: calc(100% - 16px) !important;
                }
            }

            h2 {
                font-weight: normal;
            }

            span {
                font-size: 16px;
            }
        }

        #ratgeber .box {
            max-width: calc(50% - 16px);
        }

        div > h2 {
            font-size: 14px !important;
        }

        #blog .article {
            min-width: calc(50% - 16px) !important;

            &:first-child {
                min-width: calc(100% - 16px) !important;
            }
        }

        a {
            div {
                font-size: 16px;
            }

            p {
                font-size: 16px;
                padding: 10px 0;
            }
        }

        #welten,
        #blog,
        #ratgeber,
        #blog-archive,
        #ratgeber-lexikon {
            > h1,
            > h2 {
                font-size: 20px;
                margin: 20px 0 10px 0 !important;
            }

            > h3 {
                font-size: 20px;
                margin: 20px 0 10px 0 !important;
            }
        }
    }
}
