@each $level, $styles in $headlines {
    @if $level == "default" {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include print($styles);
        }
    } @else {
        #{$level} {
            @include print($styles);
        }
    }
}
