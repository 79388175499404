// Warenkorb: Übersicht und Kaufbutton klicken / Gutschein einlösen ...
.wk_pen {
    display: none;
}

.anzahl_in_wk {
    pointer-events: none;
}

#wk_summe,
#wk_bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.wk_tab_summe {
    tbody {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-end;
    }

    #wk_summe {
        .wk_gsumme {
            width: 100%;

            b {
                display: block;
            }
        }
    }

    #wk_bottom {
        .trustedshops {
            margin-right: 20px;

            .zertifiziert {
                color: $color-3;
                width: 100%;
                font-size: 14px;
            }

            .siegel {
                width: 100%;
            }

            img {
                margin-top: 15px;
                max-width: 120px;
            }

            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .vorteile {
            margin-right: 20px;

            .container {
                display: flex;
                align-items: center;
                padding: 8px 0 8px 25px;
            }

            img {
                padding-left: 25px;
            }

            .pro {
                width: 100%;
                color: #999;
                font-size: 14px;
                text-align: left;
                padding-left: 35px;
            }

            .date {
                color: $color-3;
            }
        }

        .bezahlmethoden {
            justify-content: flex-end;
        }
    }

    .a b{
        display: grid;
    }

    div#LoginWithAmazon{
        padding: 10px 0;
    }
}

[data-pp*="wk"] .start_banner_benefits {
    display: none;
}

.wk_wk_leer ~ .trustedshops,
.wk_wk_leer ~ .vorteile {
    display: none;
}

@media (max-width: 1280px) {
    .wk_tab_summe {
        #wk_bottom {
            .vorteile {
                img {
                    padding-left: 0;
                }
            }
        }
    }
}

@media (min-width: 1281px) {
    .wk_tab_summe {
        #wk_bottom {
            .vorteile {
                max-width: 360px;
            }
        }
    }

    .wk .wk_anz_td {
        min-width: 115px;
    }
}

@media (max-width: 1024px) {
    .wk_tab_summe {
        #wk_bottom {
            .trustedshops {
                margin: 0;
            }

            .vorteile {
                margin: 20px 0 0 0;
            }

            .bezahlmethoden {
                justify-content: flex-end;
                order: -1;
                margin-bottom: 55px;
            }
        }
    }
}

@media (min-width: 1025px) {
    .wk_tab_summe {
        #wk_bottom {
            .trustedshops {
                min-width: 220px;
                flex: 0 1;
            }

            .trustedshops,
            .vorteile {
                margin: -30px 20px 30px 0;
            }
        }
    }
}

@media (max-width: 576px) {
    .wk_tab_summe {
        #wk_bottom {
            .vorteile {
                img {
                    padding-left: 30px;
                }
            }
        }
    }
}

/* ==========================================================================
08. Warenkorb
========================================================================== */

[data-pp="wk"] .tab_k {
    display: none;
}

[data-pp="wk"] #wk_topping {
    margin: 0 auto 0 auto;
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
}

img.wk_icon {
    width: 24px;
    margin: 0 13px -1px 4px;
}

p#wk_anz_ueb {
    font-size: 1.5rem;
    margin: 5px 10px 15px 10px;
    color: #999;
}

p#wk_anz_ueb i {
    font-style: normal;
}

section.fortschritt {
    cursor: default;
}

section.fortschritt ul {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    margin: 20px 0;
    overflow: hidden;
}

.fortschritt ul li {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    position: relative;
}

.fortschritt ul li.active {
    background: $color-3;
}

section.fortschritt ul li a {
    color: #333;
    padding: 6px 20px;
}

section.fortschritt ul li.active a {
    color: #fff;
    position: relative;
    z-index: 1;
}

.fortschritt li.active.last a {
    border: 4px solid #fff;
    background: #fff;
    color: #999;
    margin-right: -15px;
}

.breadcrumb_wk {
    display: none;
}

.wk_wk_leer + hr {
    display: none;
}

.wk_wk_leer {
    text-align: center;
    margin: 20px 0 50px 0;
    background: #fff;
    padding: 40px;
    font-size: 2rem;
}

.wk_best_2,
.wk_best_1 {
    color: $color-3;
}

.wk_best_2 span,
.wk_best_3 {
    color: red;
}

.fortschritt ul li.active + li.active:before {
    content: "";
    height: 50px;
    width: 400%;
    background: $color-3;
    display: block;
    position: absolute;
    right: 80%;
    z-index: 0;
}

.fortschritt ul li:last-child {
    padding-right: 10px;
}

.fortschritt ul li:first-child {
    padding-left: 10px;
}

.fortschritt ul li:first-child {
    padding: 0 5px;
}

.fortschritt ul > li.active.last:first-child a {
    margin: 0 -10px 0 0;
}

.fortschritt ul li.active:after {
    content: "";
    background: url(#{$cdn}/icon_check_w.svg) 0 0 no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 15px;
    right: -11px;
}

.fortschritt ul li.active.last:after {
    background-image: none;
}

.fortschritt li.active.last:last-child a {
    margin-right: -5px;
}

.fortschritt ul li.active.last:after {
    background: $color-3;
    height: 50px;
    top: 0;
    right: -10px;
    width: 350%;
    z-index: 0;
}

.fortschritt ul li.active:nth-last-of-type(2) {
}

.wk_plus_minus img,
.wk_loe {
    max-width: 20px;
    margin: 0 5px;
}

span.wk_plus_minus a:hover img,
.wk_loe:hover {
    transform: scale(1.2);
    transition: all 200ms ease-in-out;
}

img.wk_pic,
img.wk_zk_pic,
td.zk_wk_pic img {
    max-width: 80px;
}

.wk_zk .zk_wk_lager {
    display: none;
}

input.input_wk {
    padding: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    width: 60px;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
    color: #000;
}

#shopMitte center font[color="red"] b {
    background: $color-3;
    padding: 20px 30px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    display: block;
    line-height: 160%;
}

table.wk {
    padding: 50px 20px 0px 20px;
}

table.wk_zk {
    padding: 50px 0 0 0;
}

table.wk_tab_summe {
    padding: 20px 25px 0px 25px;
}

table.wk a.sc_link {
    display: block;
}

span.anzahl_in_wk a {
    font-size: 2rem;
}

table.wk .name_in_wk,
table.wk_zk .name_in_wk {
    color: #666;
    font-size: 2rem;
    text-align: left;
    display: block;
    padding: 0 0 5px 0;
}

span.a3.wk_ausfuehrung b {
    color: #999;
    font-size: 1.5rem;
}

span.name_in_wk {
    padding: 0 10px 0 0;
}

table.wk > tbody > tr,
table.wk_zk > tbody > tr {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

table.wk > tbody > tr:last-child,
table.wk_zk > tbody > tr:last-child {
    border: none;
    margin: 0 0 15px 0;
}

.weitkaufbutt {
    display: none !important;
}

span.b.wk_gsumme {
    font-size: 2.6rem;
    display: inline-block;
    position: relative;
}

span.b.zk_gsum {
    position: relative;
}

span.b.wk_gsumme span {
    font-size: 0.7rem;
    color: #888;
    font-weight: 100;
}

.wk_zk td:nth-of-type(3),
.wk_zk td:nth-of-type(7) {
    display: none;
}

table.wk tr td,
table.wk_zk tr td {
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    background: #fff;
    margin: 0 5px;
    padding: 10px;
}

.wk .wk_pic_td,
.wk_zk .zk_wk_pic {
    order: 1;
    flex-grow: 0;
}

.wk .wk_anz_td,
.wk_zk .zk_wk_anz {
    order: 3;
    flex-grow: 0;
    min-width: 95px;
}

.wk .wk_name_td,
.wk_zk .zk_wk_name {
    order: 2;
    flex-grow: 2;
    text-align: left;
    min-width: 200px;
}

.wk .wk_preis_td,
.wk_zk .zk_wk_ep {
    order: 4;
    pointer-events: none;
}

.wk .wk_ges_preis_td,
.wk_zk .zk_wk_gsum {
    order: 5;
    pointer-events: none;
}

td.wk_preis_td br,
td.wk_ges_preis_td br,
td.zk_wk_ep br,
td.zk_wk_gsum br {
    display: none;
}

.wk_preis_td span.a,
.wk_ges_preis_td span.a,
td.zk_wk_gsum span.a,
td.zk_wk_ep span.a {
    display: block;
}

.wk_ges_preis_td strike {
    display: block;
}

span.a3 .wk_gespart,
td.zk_wk_ep .a3 nobr,
td.zk_wk_gsum .a3 nobr {
    display: none;
}

.wk_preis_td span.preis_in_wk_strike,
.a3 strike,
.a3 .wk_ges_preis_td span span,
.wk_ges_preis_td span.ges_preis_in_wk_strike {
    font-size: 1rem;
    color: red;
}

.wk_preis_td span span,
.wk_ges_preis_td span span,
.zk_wk_ep span.preis_in_wk,
.zk_wk_gsum .preis_in_wk,
.zk_wk_gsum .gespreis_in_wk {
    font-size: 2.6rem;
    font-weight: 400 !important;
}

span.b3.wk_rab_zeile.wk_gsumme {
    display: block;
    margin: 10px 22px -10px 0;
}

.wk_anz_td tr {
    display: flex;
    flex-direction: row;
}

.wk_anz_td tr td {
    width: auto !important;
}

span.wk_plus_minus {
    margin: 6px 0 0 0;
    display: inline-block;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

span.wk_plus_minus a {
    margin: 4px 0;
}

table.wk .wk_lief_td {
    display: none;
}

.wk tr.wk_ueberschrift_tr,
.wk_zk tr.tabelle_zk_th {
    display: none;
}

.wk tr:nth-of-type(2) .wk_pic_td:after,
.wk_zk tr:nth-of-type(2) .zk_wk_pic:after {
    content: "Artikel";
    display: block;
    position: absolute;
    left: 2px;
    top: -20px;
    font-size: 1.2rem;
    color: #999;
}

.wk_zk .zk_wk_anz span.a {
    height: 35px !important;
    display: inline-block;
    line-height: 35px;
}

.wk tr:nth-of-type(2) .wk_anz_td:before,
.wk_zk tr:nth-of-type(2) .zk_wk_anz:before {
    content: "Anzahl";
    display: block;
    position: absolute;
    left: 3px;
    top: -20px;
    font-size: 1.2rem;
    color: #999;
}

.wk tr:nth-of-type(2) .wk_preis_td:before,
.wk_zk tr:nth-of-type(2) .zk_wk_ep:before {
    content: "Preis";
    display: block;
    position: absolute;
    right: 3px;
    top: -20px;
    font-size: 1.2rem;
    color: #999;
}

.wk tr:nth-of-type(2) .wk_ges_preis_td:before,
.wk_zk tr:nth-of-type(2) .zk_wk_gsum:before {
    content: "Gesamtpreis";
    display: block;
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 1.2rem;
    color: #999;
}

.wk_hr_ueber_gsum {
    display: none;
}

.wk_tab_summe .zzgl_Vers_in_WK {
    display: none;
}

.wk .wk_mwst,
.wk_zk .wk_mwst {
    display: none;
}

#wk .tabelle_zk {
    position: absolute;
    left: -5000px;
}

.wk_zk_div {
    padding: 0;
    border: none;
    margin: 0;
    background-color: initial;
}

#wk_gutscheincode button {
    padding: 5px 30px;
    font-size: 0;
    border-color: #ccc;
}

#wk_gutscheincode {
    display: none;
}

form#wk_gutscheincode button img {
    width: 12px;
}

input#gutschein_code {
    width: 212px;
    height: 44px !important;
    margin: 0 10px 0 0;
    padding: 5px;
    box-sizing: border-box;
    font-size: 1.6rem;
}

.wk_tab_summe a.wk_zk_button {
    background: $color-3;
    border-color: transparent;
    color: #fff;
    padding: 15px 20px;
    margin: 20px 0 60px 0;
    font-size: 2.5rem;
    min-width: 122px;
    text-align: center;
    position: relative;
}

form #wk_gutscheincode button {
    border-color: #ccc;
}

form #wk_gutscheincode button img {
    width: 14px;
}

table#wk tr td form table:nth-of-type(2) tbody tr td table tbody tr {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.free_delivery {
    color: $color-3;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 15px 0 0;
    display: inline-block;
}

#pp_express_link,
div#LoginWithAmazon {
    padding: 25px 20px;
    margin: 20px 0 10px 0;
    font-size: 1.2rem;
    width: 286px !important;
    height: 30px;
    display: inline-block;
    box-sizing: border-box;
}

div#LoginWithAmazon {
    margin: 0;
    padding: 0;
}

.login_a div#LoginWithAmazon img {
    width: 152px !important;
}

#pp_express_link {
    background: #fff url(#{$cdn}/icon_paypal.svg) 50% 50% no-repeat;
    background-size: auto 100%;
    margin: 0 auto;
}

.wk_tab_summe a.wk_zk_button:after {
    content: "oder bezahlen mit";
    color: #666;
    display: block;
    font-size: 1.4rem;
    font-weight: 100;
    padding-top: 6px;
    margin: 30px 0 0 0;
    position: absolute;
    left: 0;
    right: 0;
}

table.tab_plu_min tr {
    display: flex;
    flex-wrap: wrap;
}

table.tab_plu_min tr td {
    padding: 0;
    text-align: center;
    margin: 7px 0;
    border: 1px solid #fff !important;
}

.wk_zk_div tr td hr {
    display: none;
}

.zk_gsum b b {
    position: absolute;
    font-size: 1.3rem;
    right: -3px;
    top: 20px;
    font-weight: 400;
    color: #666;
}

div#LoginWithAmazon img {
}

@media (max-width: 760px) {
    body #LoginWithAmazon img {
        width: 100%;
        max-height: initial !important;
    }

    body #LoginWithAmazon {
        height: auto;
    }
}

#pp_express_link img {
    display: none;
}

table.wk_tab_summe tr:nth-of-type(4) span b {
    display: flex;
    flex-direction: column;
    flex: 1;
}

figure.artikel_image.tZoom.basketFlyer {
    position: absolute;
    top: 750px;
    transition: all 3s ease-in-out;
    background: $color-3;
    border-radius: 20px;
    width: 20px !important;
    height: 20px !important;
}

.basketFlyer img.artikel-detail_img {
    width: 20px;
    mix-blend-mode: multiply;
}

@media (max-width: 1024px) {
    .wk .wk_preis_td {
        display: none;
    }
}

@media (max-width: 900px) {
    .wk .wk_pic_td {
        display: none;
    }

    .wk tr:nth-of-type(2) .wk_name_td:after,
    .wk_zk tr:nth-of-type(2) .zk_wk_name:after {
        content: "Artikel";
        display: block;
        position: absolute;
        left: 2px;
        top: -20px;
        font-size: 1.2rem;
        color: #999;
    }
}

@media (max-width: 860px) {
    .zk_wk_ep,
    .wk_zk .zk_wk_pic {
        display: none;
    }

    #wk_bottom_td {
        width: 100%;
    }

    input#gutschein_code {
        width: calc(100% - 90px);
    }

    .infotype11 table {
        width: 100%;
    }

    table.wk_tab_summe,
    table.wk {
        padding: 20px 0 0 0;
    }

    #pp_express_link,
    div#LoginWithAmazon {
        width: 100% !important;
    }

    #wk_gutscheincode {
        margin: 0;
    }

    .wk_ges_preis_td span span {
        font-size: 1.5rem;
    }

    table.wk .name_in_wk,
    table.wk_zk .name_in_wk {
        font-size: 1.5rem;
    }

    .wk .wk_name_td,
    .wk_zk .zk_wk_name {
        min-width: 0;
    }

    table.wk tr td,
    table.wk_zk tr td {
        margin: 0 4px;
        padding: 8px;
    }

    .wk_plus_minus img,
    .wk_loe {
        margin: 0 3px;
    }

    table.tab_plu_min tr td {
        padding: 0;
    }

    span.a3.wk_ausfuehrung b {
        font-size: 1.2rem;
    }

    .wk .wk_anz_td {
        min-width: 70px;
    }

    .wk_zk .zk_wk_anz {
        min-width: 40px;
    }

    span.wk_plus_minus {
        flex-wrap: wrap;
    }
}

.wk_zk_div > table > tbody > tr:nth-of-type(2) > td,
.wk_zk_div > table > tbody > tr:nth-of-type(3) > td,
.wk_zk_div > table > tbody > tr:nth-of-type(4) > td {
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 2.4rem;
    margin: 0 15px 0 0;
}

#shopLayer {
    &[data-online="1"] {
        .wk_zk_button:after {
            content: unset;
        }
    }
}
