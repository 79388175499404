// Filter und Sortierung (In diesem Container liegen auch die Breadcrumbs)
// Abstände
$filter-padding: 0.8rem;
$breadcrumb-item-padding: 0 5px;

// Farben
$filter-color: $color-1;
$filter-suche-color: #737373;
$filter-button-color: $color-3;
$filter-button-bg: $color-2;

// Größen
$filter-size: 1.6rem;
$filter-selection-size: 1.6rem;
$breadcrumb-size: 1.4rem;
$breadcrumb-weight: 400;

.li_sort_see_nrauf,
.li_sort_see_nrab,
.li_sort_see_az,
.li_sort_see_za {
    display: none;
}

#dd_sort_top {
    // ul
    list-style-type: none;
    background: #fff;
    height: 36px;
    width: 260px;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    margin: 10px;
    font-size: 1.4rem;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    margin-left: auto;

    a {
        font-size: 1.6rem;
    }

    .dd_sort_top_kopf {
        &:after {
            content: " ";
            display: block;
            background: map-get($icons, "filter") no-repeat (50% / contain);
            position: absolute;
            top: 0;
            right: 0;
            background-size: 2.5rem;
            height: 3.5rem;
            width: 5rem;
        }

        + ul {
            display: none;
        }
    }

    &.open {
        box-shadow: 1px 10px 10px 0 rgba(0, 0, 0, 0.2);

        .dd_sort_top_kopf {
            padding-bottom: 1rem;

            + ul {
                display: block;
                list-style-type: none;
                position: absolute;
                max-height: 500px;
                background: #fff;
                left: 0;
                top: 36px;
                width: 100%;
                padding: 0.8rem 1.2rem;
                box-sizing: border-box;
                z-index: 2;
                box-shadow: 1px 10px 10px 0 rgba(0, 0, 0, 0.2);

                li {
                    list-style-type: none;
                    padding: 0.6rem 0;
                }
            }
        }
    }
}

.top_bread-sort {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px 0px 10px;

    > #blaettern_oben {
        // Sortierung + Anzahl gefundener Artikel
        order: 1;

        .anzahl {
            display: none;
        }

        #pag_se_all {
            display: none;
        }
    }

    .tab_k {
        .BreadcrumbList {
            // Breadcrumbs
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            /*
            > span:first-child + .breadcrum_arrow + span {
                display: none;
            }

            a[href="/"] {
                display: none;
            }
            */

            > span {
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 150px;
                white-space: nowrap;
                font-size: 14px;

                &:last-child {
                    a {
                        pointer-events: none;
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }

            .location_crumb {
                // a

                > * {
                    font-size: $breadcrumb-size;
                    font-weight: $breadcrumb-weight;
                    text-decoration: none;
                    color: $color-1;
                    padding: $breadcrumb-item-padding;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.see_alle_dd {
    // Filter
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    .see_multi_loesch_buttons {
        width: 100%;
    }

    form.see_multi_loesch_form {
        display: flex;
        margin-bottom: 0;

        .see_multi_loesch {
            // button
            padding: 0.5rem !important;
            margin: 1rem 0 0 0 !important;
            font-size: 1.2rem !important;
            background: transparent !important;
            color: $color-1 !important;
            box-shadow: none !important;

            &:before {
                content: "";
                background: url(https://cdn.hut.de/icon_close.svg) no-repeat !important;
                background-size: 10px 10px !important;
                background-position: right 10px bottom 3px !important;
                height: 25px;
                padding: 0 0 0 25px;
                margin: 0 0.5rem 0 0;
            }
        }
    }

    form.loesch_alle,
    .see_multi_loesch_txt {
        display: none;
    }

    form[name="dd_aug_form"] {
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        flex: 1;

        > ul {
            list-style-type: none;
            width: 260px;
            box-sizing: border-box;
            padding: 0.8rem 1.2rem;
            margin: 10px;
            background: $color-2;
            font-size: 1.4rem;
            font-weight: 400;
            position: relative;
            cursor: pointer;

            &#dd_her {
                .se_li_such {
                    display: block !important;
                }
            }

            > li {
                max-height: 36px;

                &.active {
                    position: absolute;
                    max-height: 500px;
                    background: $color-2;
                    left: 0;
                    top: 0;
                    width: 100%;
                    padding: 0.8rem 1.2rem;
                    box-sizing: border-box;
                    z-index: 2;
                    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
                }
            }

            div[class*="_kopf"] {
                &:after {
                    content: " ";
                    display: block;
                    background: map-get($icons, "filter") no-repeat (50% / contain);
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-size: 2.5rem;
                    height: 3.5rem;
                    width: 5rem;
                }

                a {
                    font-size: 1.6rem;
                }
            }

            .dd_list {
                display: none;
                margin-top: 10px;

                .se_li_such {
                    display: none;
                    border-bottom: 1px solid #e4e4e4;
                    margin-bottom: 10px;
                    margin-right: 15px;
                    height: 25px !important;

                    input::placeholder {
                        color: #cecece;
                    }

                    > input {
                        outline: none;
                        border: none;
                        font-size: 1.6rem;
                    }
                }

                ul {
                    padding: 0;
                    max-height: 245px;
                    overflow-y: scroll;

                    > li {
                        list-style-type: none;
                        padding: 0.5rem 0 0 0;

                        .see_multi_label {
                            // label
                            display: flex;
                            align-items: center;
                            font-size: 1.6rem;

                            > .see_multi_checkbox {
                                background: 0 0;
                                position: relative;

                                // visibility: hidden;
                                padding: 0;
                                margin: 0 1.5rem 0 0;

                                &:checked {
                                    &:before {
                                        background: $color-3;
                                        border: 2px solid $color-1-1;
                                    }
                                }

                                &::before {
                                    content: " ";
                                    display: block;
                                    width: 15px;
                                    height: 15px;
                                    border: 2px solid $color-3;
                                    position: relative;
                                    top: -4px;
                                    background: #fff;
                                    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }

                button {
                    padding: 1rem !important;
                    font-size: 1.6rem !important;
                    margin-bottom: 0;
                }
            }
        }
    }

    #filter_anzeigen {
        display: none;
    }
}

@include breakpoint("xs") {
    .top_bread-sort {
        justify-content: center;
    }

    .see_alle_dd {
        // Filter
        form[name="dd_aug_form"] {
            > ul {
                width: 100%;

                > li {
                    &.active {
                    }
                }
            }
        }

        #dd_sort_top {
            flex: 1 100%;
        }

        #filter_anzeigen {
            position: relative;
            display: flex;
            margin: 1rem;
            padding: 0.8rem 1.2rem;
            background: $color-2;
            cursor: pointer;
            flex: 1 100%;

            &:after {
                content: " ";
                display: block;
                background: map-get($icons, "filter") no-repeat (50% / contain);
                position: absolute;
                top: 0;
                right: 0;
                background-size: 2.5rem;
                height: 3.5rem;
                width: 5rem;
            }

            > span {
                font-size: 1.6rem;
            }

            &.filter_toggle {
                + form[name="dd_aug_form"] {
                    display: flex;
                }
            }

            + form[name="dd_aug_form"] {
                display: none;
            }
        }
    }
}

@include breakpoint("s") {
    .see_alle_dd {
        // Filter

        form[name="dd_aug_form"] {
            flex: unset;
        }

        #dd_sort_top {
            width: 100%;
            margin: 10px;
        }
    }

    .top_bread-sort {
        flex-direction: column;
        order: 1;
        margin: 50px 0 0 10px;
    }
}
