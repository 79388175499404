/* latin-ext */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local("Lato Light"),
        local("Lato-Light"),
        url(https://css.capuniverse.com/bin/font/lato-light-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local("Lato Light"),
        local("Lato-Light"),
        url(https://css.capuniverse.com/bin/font/lato-light.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local("Lato Regular"),
        local("Lato-Regular"),
        url(https://css.capuniverse.com/bin/font/lato-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local("Lato Regular"),
        local("Lato-Regular"),
        url(https://css.capuniverse.com/bin/font/lato.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local("Lato"),
        local("Lato-Bold"),
        url(https://css.capuniverse.com/bin/font/lato-bold-ext.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local("Lato"),
        local("Lato-Bold"),
        url(https://css.capuniverse.com/bin/font/lato-bold.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/***************************************************************/

// Work Sans
@font-face {
    font-family: "Work Sans Light";
    font-style: normal;
    font-weight: 300;
    src:
        local("Work Sans Light"),
        local("WorkSans-Light"),
        url("https://css.capuniverse.com/bin/font/work-sans-v5-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    src:
        local("Work Sans"),
        local("WorkSans-Regular"),
        url("https://css.capuniverse.com/bin/font/work-sans-v5-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: "Work Sans Bold";
    font-style: normal;
    font-weight: 700;
    src:
        local("Work Sans Bold"),
        local("WorkSans-Bold"),
        url("https://css.capuniverse.com/bin/font/work-sans-v5-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
