$button-color: $color-2;
$button-bg: $color-3;
$button-padding: 2rem;
$button-height: auto;
$button-width: 26rem;
$button-size: 2rem;

#shopLayer {
    button {
        max-width: $button-width;
        height: $button-height;
        background: $button-bg;
        color: $button-color;
        padding: $button-padding;
        box-shadow:
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
        border: none;
        border-radius: 0;
        font-size: $button-size;
        outline: none;
        cursor: pointer;
        z-index: 1;

        &.in-wk {
            border-radius: 50%;
            width: 8rem;
            height: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 30px;
            bottom: 20px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.7);
        }
    }
}

#infTab button#kaufabschlussButton {
    display: block;
}

@include breakpoint("s") {
    #shopLayer {
        button {
            &.in-wk {
                bottom: 10px;
            }
        }
    }
}

@include breakpoint("xs") {
    #shopLayer {
        button {
            &.in-wk {
                right: 15px;
                width: 7rem;
                height: 7rem;
                bottom: 15px;

                &:after {
                    height: 30px !important;
                }
            }
        }
    }
}
