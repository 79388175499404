// Pop-Up in Warenkorb legen
#cs_dark_matter {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    z-index: 4;
    top: 0;
    left: 0;
    overflow: hidden;
    overscroll-behavior: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    #cs_layer {
        max-width: 960px;
        margin: 70px auto;
        background: #fff;
        box-shadow: 0 0 23px rgba(0, 0, 0, 0.3);

        .artikel-im-wk {
            align-items: center;
            padding: 2rem;

            span {
                font-size: 1.4rem;
            }
        }

        .buttons {
            margin: 0 0 0 16.5rem;

            button {
                padding: 1.2rem 2rem;
                font-size: 1.5rem;
                margin: 0 2rem 0 0;
            }
        }

        .cross {
            padding: 2rem;
            background: $color-5;
            box-sizing: border-box;
            margin-top: 4rem;
            display: flex;
            flex-direction: column;

            #cross_titel {
                text-align: center;
                order: -1;
            }

            .list_se {
                justify-content: center;

                &:empty {
                    + #cross_titel {
                        display: none;
                    }
                }
            }
        }
    }
}

@include breakpoint("m") {
    #cs_dark_matter {
        #cs_layer {
            .buttons {
                margin: 0 0 0 2rem;
            }

            .cross {
                #cross_titel {
                    font-size: 1.6rem;
                }

                .list_se {
                    .artikel {
                        flex: 0 1 calc(50% - 2rem);
                        max-width: 260px;
                    }
                }
            }
        }
    }
}

@include breakpoint("xs") {
    #cs_dark_matter {
        #cs_layer {
            .buttons {
                button {
                    padding: 1.2rem 1rem;
                    margin: 0 1.4rem 0 0;
                }
            }

            .cross {
                #cross_titel {
                }

                .list_se {
                    &:empty {
                        + #cross_titel {
                        }
                    }
                }
            }
        }
    }
}
