html {
    font-size: 62.5%;
    background: #eeeeee;

    body {
        overflow-x: hidden;
        font-family: "Lato", $fonts-1;

        img {
            max-width: 100%;
            height: auto;
        }

        .display-none {
            display: none;
        }

        input {
            outline: none;
        }

        #tsbadge4_db8d3657bdbe440c985ae127463eaad4 {
            position: fixed;
            z-index: 1;
        }

        #tsbadgeResponsiveTop_db8d3657bdbe440c985ae127463eaad4,
        #se_to_top {
            display: none !important;
        }

        #cookieWrapper {
            display: none;
        }
    }

    #shopMitte2 {
        display: none;
    }

    #shopMain {
        margin: 8rem auto 2rem auto;
        width: calc(6 * 260px + 6 * 20px);

        #mz_leer {
            font-size: 2rem;
            text-align: center;
        }

        @include breakpoint("xl") {
            width: calc(5 * 260px + 5 * 20px);
        }

        @include breakpoint("l") {
            width: calc(4 * 260px + 4 * 20px);
        }

        @include breakpoint("m") {
            width: calc(3 * 260px + 3 * 20px);
        }

        @include breakpoint("s") {
            width: calc(2 * 260px + 2 * 20px);
        }

        @include breakpoint("xs") {
            width: 100%;
        }

        a {
            text-decoration: none;
        }

        .shop_tab_mitte {
            display: flex;
            flex-direction: column;
        }
    }
}

// @if $local_gulp == "1" {
//     //wenn in der .env LOCAL_GULP = 1 ist, dann wird hiermit der rote Punkt für den Redirector angezeigt

//     body:before {
//         content: "R";
//         position: fixed;
//         z-index: 100000000000000;
//         background: rgba(255, 82, 82, 1);
//         box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
//         animation: pulse-red 2s infinite;
//         border-radius: 1000px;
//         width: 25px;
//         height: 25px;
//         color: white;
//         text-align: center;
//         line-height: 25px;
//         margin: 5px;
//         font-size: 22px;
//     }

//     //Animation von https://www.florin-pop.com/blog/2019/03/css-pulse-effect/
//     @keyframes pulse-red {
//         0% {
//             transform: scale(0.95);
//             box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
//         }

//         70% {
//             transform: scale(1);
//             box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
//         }

//         100% {
//             transform: scale(0.95);
//             box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
//         }
//     }
// }