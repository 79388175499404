$header-height: 6rem;
$item-padding: 0 2rem;

#shopHeader {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 21;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.6);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $color-1;
        color: #fff;
        height: $header-height;

        &__menu-icon {
            padding: $item-padding;
        }

        &__logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            img {
                height: $header-height;
            }
        }

        &__suche {
            margin-left: auto;
            position: relative;

            &:after {
                content: "";
                display: block;
                background: map-get($icons, "suche") no-repeat;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 0;
                top: -1.9rem;
                cursor: pointer;
            }
        }

        &__wk {
            padding: $item-padding;
            text-decoration: none;
            position: relative;
            margin: 1rem 0 0 0;

            &:before {
                content: attr(data-anz-in-wk);
                position: absolute;
                right: 27px;
                top: -10px;
                background: $color-3;
                color: $color-2;
                height: 1.6rem;
                width: 1.6rem;
                border-radius: 50%;
                padding: 0.2rem;
                display: flex;
                justify-content: center;
                font-weight: 700;
                z-index: 0;
                clip: rect(0px, 20px, 16px, 0px);
            }

            &:after {
                content: "";
                display: block;
                background: map-get($icons, "wk") no-repeat;
                height: 35px;
                width: 35px;
            }

            > img {
                height: 3.5rem;
                z-index: 1;
            }
        }
    }
}

@include breakpoint("s") {
    #shopHeader {
        .header {
            &__menu-icon {
            }

            &__logo {
                img {
                }
            }

            &__suche {
                &:after {
                    position: unset;
                    cursor: pointer;
                }

                > input {
                    display: none;

                    // TODO: Icon einbauen statt input
                }
            }

            &__wk {
                padding: 0 1rem;

                &:before {
                    right: 16px;
                }
            }
        }
    }
}

@include breakpoint("xs") {
    #shopHeader {
        .header {
            &__menu-icon {
            }

            &__logo {
                img {
                }
            }

            &__suche {
                &:after {
                    height: 25px;
                    width: 25px;
                    top: -1rem;
                }

                > input {
                    display: none;
                }
            }

            &__wk {
                &:before {
                }
            }
        }
    }
}
