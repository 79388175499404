// Abstände
$detail-box-padding: 1.2rem;

// Farben, text-decoration etc.
$detail-box-bg: $color-4;
$detail-box-color: $color-1;
$detail-box-link-decoration: underline;

// Größen
$detail-box-text-size: 1.6rem;
$detail-box-heading-size: 1.8rem;
$detail-box-heading-weight: 500;
$detail-box-product-brand-size: 3rem;
$detail-box-product-name-size: 1.8rem;
$detail-box-product-brand-weight: 700;
$detail-box-product-name-weight: 500;
$detail-article-image-width: 650px; // max-width
$detail-article-image-height: 650px; // max-height

#shopLayer[data-pp*="aa"] {
    background: $color-2;

    .basketFlyer {
        background: $color-3 !important;
    }

    #shopMain {
        width: 100%;

        .detail {
            margin: -2rem 0 0 0;

            &__headline {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: $color-4;
                padding: 0 15px;

                &__marke {
                    font-family: "Work Sans Bold", $fonts-1;
                    text-transform: uppercase;
                    font-size: 2.6rem;
                    order: -1;
                    flex: 0 20%;
                    font-weight: 700;
                }

                &__name {
                    font-weight: 300;
                    font-size: 1.8rem;
                    flex: 1 60%;
                    text-align: center;
                }

                > .favorit {
                    flex: 0 20%;
                    text-align: right;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;

                    .se_beobachten {
                        &:after {
                            content: " ";
                            display: block;
                            background: map-get($icons, "star-outline") no-repeat 0 0;
                            background-size: contain;
                            width: 30px;
                            height: 30px;
                        }

                        &.gemerkt {
                            &:after {
                                background: map-get($icons, "star-full") no-repeat 0 0;
                                background-size: contain;
                            }
                        }
                    }
                }
            }

            &__box {
                display: flex;
                justify-content: center;
                height: calc(100vh - 220px);
                padding: 0 1rem;

                > #aa_pic_show {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-height: calc(100% - 180px);

                    .minipics {
                        a.active {
                            input {
                                + label {
                                    &:before {
                                    }

                                    &:after {
                                        background: $color-3;
                                    }
                                }
                            }
                        }

                        input {
                            display: none;

                            + label {
                                position: relative;
                                margin: 0 0.2rem;

                                &:before {
                                    content: "";
                                    display: inline-flex;
                                    width: 18px;
                                    height: 18px;
                                    border: 1px solid #ddd;
                                    border-radius: 100%;
                                    background: #fff;
                                    cursor: pointer;
                                }

                                &:after {
                                    content: "";
                                    width: 10px;
                                    height: 10px;
                                    background: #dedede;
                                    position: absolute;
                                    top: -4px;
                                    left: 5px;
                                    border-radius: 100%;
                                    transition: all 0.5s ease;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    > .image-box {
                        display: flex;
                        align-items: center;
                        height: calc(100vh - 370px);

                        .artikel_image {
                            margin: 0.5rem;
                            position: relative;

                            img {
                                max-height: calc(100vh - 370px);
                                padding: 2rem;
                                box-sizing: border-box;
                            }

                            #tZoomViewer {
                                padding: 2rem;
                                max-height: calc(100vh - 370px);
                                width: 100% !important;
                                height: 100% !important;
                                left: 0 !important;
                                top: 0 !important;
                                box-sizing: border-box;
                                margin: 0;
                            }
                        }

                        .left,
                        .right {
                            &:after {
                                content: " ";
                                display: block;
                                width: 15px;
                                height: 25px;
                                cursor: pointer;
                            }
                        }

                        .left {
                            &:after {
                                background: map-get($icons, "arrow-left") no-repeat;
                                margin-right: 1rem;
                            }
                        }

                        .right {
                            &:after {
                                background: map-get($icons, "arrow-right") no-repeat;
                                margin-left: 1rem;
                            }
                        }
                    }

                    .arrow-down {
                        background: map-get($icons, "arrow-down-double") no-repeat;
                        cursor: pointer;
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 145px;
                    }
                }

                > #basket-wrapper {
                    position: fixed;
                    bottom: 0;
                    height: 125px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background: $color-1;
                    color: $color-2;
                    z-index: 1;

                    &.zeige-impressum-etc {
                        > .rechtliches {
                            display: flex;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            list-style-type: none;
                            padding-left: 1rem;

                            li {
                                padding: 0 1rem;

                                a {
                                    color: $color-2;
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }

                    &:not(.zeige-impressum-etc) {
                        > .rechtliches {
                            display: none;
                        }
                    }

                    .mwst {
                        font-size: 1.4rem;
                        padding: 0.5rem 0 0 0;
                        font-weight: 300;
                        width: 100%;
                        text-align: center;

                        a {
                            color: $color-2;
                        }
                    }

                    > .in-wk {
                        &:after {
                            content: "";
                            display: block;
                            background: map-get($icons, "wk-plus") no-repeat;
                            height: 40px;
                            width: 40px;
                        }
                    }

                    > #preis {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;

                        > .preis-box {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            padding: 0 0 0 1rem;

                            > #preisDiv {
                                font-size: 5rem;
                                font-weight: 700;
                            }

                            > .sonderpreis {
                                order: -1;
                                padding: 0 2rem 0 0;
                            }

                            > .rabatt-box {
                                font-size: 2rem;
                                display: flex;
                                flex-direction: column;

                                > .aa-proz {
                                    background: map-get($icons, "streichpreis") no-repeat center;
                                    order: -1;
                                    text-align: center;
                                }

                                > .d15_vk {
                                    text-decoration: line-through;
                                }
                            }
                        }
                    }
                }
            }

            &__infos {
                width: 880px;
                display: flex;
                margin: 0 auto 12rem auto;

                > .left {
                    display: flex;
                    flex-direction: column;
                    width: 320px;
                    margin-right: 20px;
                }

                > .right {
                    display: flex;
                    flex-direction: column;
                    width: 540px;
                }

                .box {
                    background: $color-4;
                    padding: 2rem;
                    margin-bottom: 2rem;
                    font-size: 1.6rem;

                    &.benefits {
                        .row {
                            display: flex;
                            align-items: center;
                            padding: 8px 0;

                            img {
                                width: 40px;
                                padding-right: 30px;
                            }
                        }
                    }

                    .it_features_ul {
                        padding-left: 1.5rem;
                        margin: 0;

                        br,
                        hr {
                            display: none;
                        }
                    }

                    &.beschreibung {
                        &:not(.text_da) {
                            display: none;
                        }
                    }

                    > h3 {
                        font-weight: 700;
                        font-size: 1.8rem;
                        margin: 0 0 20px 0;
                    }

                    .ada_text {
                        // Produktbeschreibung
                        color: $color-1;

                        h1,
                        h2,
                        h3,
                        h4 {
                            font-size: 1.6rem;
                            color: $color-1;
                        }
                    }

                    &.features {
                        .it_features_head,
                        .a > img {
                            display: none;
                        }
                    }

                    &.hilfe {
                        a {
                            display: table;
                            text-decoration: underline;
                        }

                        .pink {
                            margin: 10px 0 0 0;
                            color: $color-3;
                        }
                    }

                    &.marke {
                        img {
                            height: 6rem;
                            display: block;
                            margin: 0 auto 1rem auto;
                        }

                        a {
                            text-decoration: underline;
                            text-align: center;
                            display: block;
                        }
                    }
                }
            }

            &__basket {
                margin: 0 auto 3rem auto;
                width: 880px;
                display: flex;
                flex-direction: column;
                outline: none;

                .kauf-abschliessen {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 3rem 0;

                    #preis {
                        text-align: right;
                        margin: 0 3rem 0 0;
                        justify-content: flex-end;
                        flex: 1 50%;

                        #preisDiv {
                            font-size: 3.2rem;
                            font-weight: 700;
                            margin: 0 0 0.5rem 0;
                        }

                        .d15_vk {
                            font-size: 2rem;
                            margin: 0.5rem 0 2rem 0;
                            text-decoration: line-through;
                            color: #b9b9b9;
                        }

                        .mwst {
                            font-size: 1.2rem;
                        }
                    }

                    #inWK {
                        text-align: center;
                        display: grid;
                        flex: 1 50%;
                        z-index: 0;

                        #box_lagerinfo {
                            position: relative;
                            padding: 0 0 1rem 0;

                            #html_lagerinfo {
                                font-size: 1.5rem;
                                font-weight: 700;
                                text-align: left;
                                color: $color-3;
                            }
                        }
                    }
                }

                .konfiguration {
                    display: flex;

                    > .farben {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        flex: 1 50%;
                        padding: 1rem;
                        border-right: 1px solid $color-3;

                        .fagr {
                            width: calc(100% / 4 - 1.6rem);
                            box-sizing: border-box;
                            padding: 0.5rem;
                            margin: 0.8rem;
                            cursor: pointer;
                            text-align: center;

                            &.active {
                                outline: 2px solid $color-3;
                            }

                            &.weg {
                                opacity: 0.3;
                            }
                        }

                        .title {
                            #farbname {
                                padding: 0.5rem;
                            }
                        }
                    }

                    > .size {
                        padding: 1rem;
                        flex: 1 50%;

                        .fagrWrapper {
                            display: flex;
                            flex-wrap: wrap;

                            .fagr {
                                padding: 0.5rem;
                                margin: 0.8rem;
                                cursor: pointer;
                                outline: 2px solid $color-4;

                                &.active {
                                    outline: 2px solid $color-3;
                                }

                                span {
                                    font-size: 1.4rem;
                                }

                                &.weg {
                                    opacity: 0.3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("m") {
    #shopLayer[data-pp*="aa"] {
        #shopMain {
            .detail {
                &__headline {
                    &__marke {
                    }

                    &__name {
                    }

                    > .favorit {
                    }
                }

                &__box {
                    > #aa_pic_show {
                        > .image-box {
                        }

                        .arrow-down {
                        }
                    }

                    > #basket-wrapper {
                        > #preis {
                            > #preisDiv {
                            }

                            > .mwst {
                            }

                            > .preis-box {
                                > .aa-proz {
                                }

                                > .d15_vk {
                                }
                            }
                        }
                    }
                }

                &__infos {
                    flex-direction: column;
                    padding: 0 1.5rem;
                    width: unset;

                    > .left {
                        margin-right: 0;
                        width: unset;
                    }

                    > .right {
                        width: unset;
                        order: -1;
                    }

                    .box {
                        > h3 {
                        }

                        .ada_text {
                            // Produktbeschreibung

                            h1,
                            h2,
                            h3,
                            h4 {
                            }
                        }

                        &.features {
                            .it_features_head,
                            .a > img {
                            }
                        }

                        &.hilfe {
                            a {
                            }

                            .pink {
                            }
                        }

                        &.marke {
                            img {
                            }

                            a {
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("s") {
    #shopLayer[data-pp*="aa"] {
        #shopMain {
            .detail {
                &__headline {
                    flex-direction: column;

                    &__marke {
                        margin: 1rem 0 0.5rem 0;
                    }

                    &__name {
                        margin: 0 0 1rem 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                    }

                    > .favorit {
                        position: absolute;
                        right: 2rem;
                        top: 1rem;
                    }
                }

                &__box {
                    > #aa_pic_show {
                        > .image-box {
                        }

                        .arrow-down {
                            bottom: 120px;
                        }
                    }

                    > #basket-wrapper {
                        height: 100px;

                        > .rechtliches {
                            display: none !important;
                        }

                        > #preis {
                            justify-content: flex-start;
                            padding: 2rem;

                            > #preisDiv {
                                font-size: 3.5rem;
                            }

                            > .mwst {
                                text-align: left;
                            }

                            > .preis-box {
                                padding: 0;

                                > .sonderpreis,
                                > #preisDiv {
                                    font-size: 3.5rem;
                                    padding: 0 1rem 0 0;
                                }

                                > .rabatt-box {
                                    font-size: 1.6rem;

                                    > .aa-proz {
                                    }

                                    > .d15_vk {
                                    }
                                }
                            }
                        }
                    }
                }

                &__infos {
                    flex-direction: column;
                    padding: 0 1.5rem;
                    width: unset;
                    margin: 0 auto 10rem auto;

                    > .left {
                        margin-right: 0;
                        width: unset;
                    }

                    > .right {
                        width: unset;
                    }

                    .box {
                        > h3 {
                        }

                        .ada_text {
                            // Produktbeschreibung

                            h1,
                            h2,
                            h3,
                            h4 {
                            }
                        }

                        &.features {
                            .it_features_head,
                            .a > img {
                            }
                        }

                        &.hilfe {
                            a {
                            }

                            .pink {
                            }
                        }

                        &.marke {
                            img {
                            }

                            a {
                            }
                        }
                    }
                }

                &__basket {
                    padding: 0 1.5rem;
                    width: unset;

                    .kauf-abschliessen {
                        #preis {
                            #preisDiv {
                            }

                            .mwst {
                            }
                        }

                        #inWK {
                        }
                    }

                    .konfiguration {
                        flex-wrap: wrap;

                        > .farben {
                            flex: 0 1 100vw;
                            border: none;
                            justify-content: center;

                            .fagr {
                                width: calc(100% / 3 - 1.6rem);

                                &.active {
                                }
                            }

                            .title {
                                #farbname {
                                }
                            }
                        }

                        > .size {
                            display: flex;
                            justify-content: center;

                            .fagrWrapper {
                                .fagr {
                                    &.active {
                                    }

                                    span {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("xs") {
    #shopLayer[data-pp*="aa"] {
        #shopMain {
            .detail {
                &__headline {
                    &__marke {
                    }

                    &__name {
                    }

                    > .favorit {
                        background: $color-4;
                        right: 1rem;
                    }
                }

                &__box {
                    > #aa_pic_show {
                        > .image-box {
                            margin-top: 1rem;

                            .artikel_image img {
                                padding: 0.5rem;
                            }
                        }

                        .arrow-down {
                        }
                    }

                    > #basket-wrapper {
                        > #preis {
                            > #preisDiv {
                            }

                            > .mwst {
                            }

                            > .preis-box {
                                > .sonderpreis,
                                > #preisDiv {
                                }

                                > .rabatt-box {
                                    > .aa-proz {
                                    }

                                    > .d15_vk {
                                    }
                                }
                            }
                        }
                    }
                }

                &__infos {
                    > .left {
                    }

                    > .right {
                        width: unset;
                    }

                    .box {
                        > h3 {
                        }

                        .ada_text {
                            // Produktbeschreibung

                            h1,
                            h2,
                            h3,
                            h4 {
                            }
                        }

                        &.features {
                            .it_features_head,
                            .a > img {
                            }
                        }

                        &.hilfe {
                            a {
                            }

                            .pink {
                            }
                        }

                        &.marke {
                            img {
                            }

                            a {
                            }
                        }
                    }
                }

                &__basket {
                    .kauf-abschliessen {
                        flex-direction: column;

                        #preis {
                            margin: 0 0 2rem 0;
                            text-align: center;

                            #preisDiv {
                            }

                            .d15_vk {
                                margin: 0 0 0.5rem 0;
                            }

                            .mwst {
                            }
                        }

                        #inWK {
                            #box_lagerinfo {
                                #html_lagerinfo {
                                    position: unset;
                                    padding: 0 0 1rem 0;
                                    text-align: center;
                                }
                            }

                            #inWKsenden {
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
