// Abstände
$menu-item-first-padding: 0.5rem 3rem; // oberste Hierarchie-Ebene
$menu-item-second-padding: 0.5rem 4rem; // zweite Ebene

// Farben
$menu-item-color: $color-1;
$menu-item-highlighted-color: $color-2;

// Größen
$menu-item-active-weight: 600;

.menu {
    display: none;
}

#menuToggle {
    position: relative;

    input {
        display: block;
        width: 40px;
        height: 33px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */

        -webkit-touch-callout: none;

        &:checked {
            ~ #overflow-closer {
                position: fixed;
                top: 0;
                background: rgba(0, 0, 0, 0.5);
                width: 100%;
                height: 100%;
                display: block;
                z-index: -2;
            }

            ~ span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: $color-2;

                &.menu-span-2 {
                    transform: rotate(-45deg) translate(0, -1px);
                    position: absolute;
                    bottom: -12px;
                    left: -3px;
                }

                &.menu-span-3 {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }
            }

            ~ ul {
                transform: none;
            }
        }
    }

    span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: $color-2;
        z-index: 1;
        transform-origin: 4px 0px;
        transition:
            transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            opacity 0.55s ease;

        &:first-child {
            transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
    }
}

#menu {
    position: absolute;
    width: 330px;
    margin: 19px 0 0 -50px;
    padding: 0 0 10px 30px;
    background: $color-2;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    box-shadow: inset 5px 2px 6px 0 rgba(0, 0, 0, 0.6);
    height: 100vh;
    overflow-y: scroll;
    z-index: -1;

    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    &::-webkit-scrollbar {
        width: 0.2rem;
        left: -100px;
    }

    &::-webkit-scrollbar {
        width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
        background: $color-4;

        /* change me to blue to match the background */
    }

    &::-webkit-scrollbar-thumb {
        background: grey;
        border: 5px transparent solid;

        /* change border color to blue to match the background */
    }

    a {
        text-decoration: none;

        li {
            padding: 0.5rem 0;
            font-size: 1.6rem;
        }
    }

    // Hauptmenü
    .mainmenu {
        .merkliste {
            padding-bottom: 15rem;
        }

        .link_login {
            position: relative;
            box-sizing: border-box;
            background: $color-2;

            &:before {
                content: " ";
                display: block;
                background: map-get($icons, "login") no-repeat center;
                background-size: contain;
                position: absolute;
                left: 2rem;
                top: 1rem;
                height: 2rem;
                width: 2rem;
            }

            > a {
                padding: 1rem 5rem !important;
            }
        }

        .link_merkliste {
            position: relative;
            box-sizing: border-box;
            background: $color-2;

            &:before {
                content: " ";
                display: block;
                background: map-get($icons, "star-outline") no-repeat center;
                background-size: contain;
                position: absolute;
                left: 2rem;
                top: 1rem;
                height: 2rem;
                width: 2rem;
            }

            > a {
                padding: 1rem 5rem !important;
            }
        }
        .link_inspirationen {
            position: relative;
            box-sizing: border-box;
            background: $color-2;

            &:before {
                content: " ";
                display: block;
                background: map-get($icons, "inspiration") no-repeat center;
                background-size: contain;
                position: absolute;
                left: 2rem;
                top: 1rem;
                height: 2rem;
                width: 2rem;
            }

            > a {
                padding: 1rem 5rem !important;
            }
        }

        > ul {
            padding: 0;

            a {
                font-size: 1.4rem;
                color: $color-1;

                &:hover {
                    background: $color-4;
                }
            }

            li {
                list-style-type: none;
                border-top: 2px solid $color-4;
                position: relative;

                .head {
                    color: $color-1;
                    font-weight: 700;
                    text-align: center;
                    font-size: 1.2rem;
                    padding: 0.5rem 0;
                    background: $color-4;
                }

                .menu-opener {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                    text-align: center;
                    margin: 0;
                    width: 5rem;
                    height: 40px;
                    display: block;
                    background: transparent;

                    &:after {
                        content: " ";
                        display: block;
                        background: map-get($icons, "filter") no-repeat center;
                        background-size: 2.5rem;
                        height: 4rem;
                        width: 5rem;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }

                .alvl_1 {
                    padding: 1rem 2rem;
                    font-size: 1.7rem;
                }

                .submenu {
                    width: 100%;

                    .sub-submenu {
                        width: 100%;
                    }
                }

                width: 100%;
            }

            > li {
                &.active {
                    > a {
                        font-weight: 700;
                    }
                }

                > a {
                    display: flex;
                }

                > div {
                    display: none;

                    .lvl_2 a {
                        display: block;
                        padding: 0.8rem 0 0.8rem 4rem;
                        font-size: 1.5rem;
                    }

                    .lvl_3 a {
                        padding: 0.8rem 0 0.8rem 6rem;
                    }
                }
            }
        }

        > .lvl_1 {
            div {
                display: none;
            }
        }

        .menu-open {
            > div {
                display: initial;
            }

            > a {
                background: $color-4;
                font-weight: 700;
            }
        }

        ul {
            padding: 0;
        }
    }
}
