#shopLayer {
    .kontakt-ok {
        font-size: 1.6rem;
        padding: 1rem 2rem;

        h1 {
            color: $color-3;
        }
    }

    .service {
        margin: 0 1rem;

        h1,
        h2 {
            color: $color-3;
            font-weight: 700;
        }

        &__content {
            display: flex;

            .kontaktformular {
                button {
                    padding: 1rem;
                    margin: 0 2rem 1rem auto;
                    display: flex;
                }

                .form-group {
                    position: relative;

                    &.active {
                        label {
                            position: absolute;
                            top: -1.3rem;
                            font-size: 1rem;
                            color: $color-3;
                            transition-duration: 0.1s;
                            font-weight: 700;
                        }
                    }

                    &.nachricht {
                        flex: 1 0 calc(100% - 2rem);

                        &.active {
                            label {
                                top: -2.7rem;
                                padding-left: 0.3rem;
                            }
                        }
                    }
                }

                .form-group,
                select {
                    display: flex;
                    margin: 1rem;
                    flex: 1 0 calc(50% - 2rem);
                    background: #fff;
                    border: 0;
                }

                select {
                    border: 2px solid $color-3;
                    margin: 0 1rem 1rem 1rem;
                    font-size: 1.2rem;
                    height: 3rem;

                    &:focus {
                        outline: none;
                    }

                    option {
                        background: #fff;
                    }
                }

                label {
                    position: absolute;
                    padding: 2px;
                }

                input {
                    background: transparent;
                    border: none;
                    border-bottom: 2px solid $color-3;
                    color: #212121;
                    flex: 1;
                    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
                }

                .nachricht {
                    label {
                        padding: 1rem;
                    }

                    textarea {
                        flex: 1 0 50%;
                        padding: 0.5rem;
                        border: 2px solid $color-3;

                        &:focus {
                            border: 2px solid $color-3;
                            outline: none;
                        }
                    }
                }
            }

            .left {
                width: calc(50% - 2rem);
                margin: 0 2rem 0 0;
            }

            .right {
                width: calc(50%);
            }

            .box {
                background: #fff;
                padding: 1rem 2rem;
                margin: 2rem 0;
            }

            h2 {
                font-size: 1.6rem;
                margin: 1rem 0;
            }

            p,
            .box {
                font-size: 1.4rem;
                color: $color-1-1;
            }

            .groessentabelle {
                overflow-x: scroll;

                table {
                    margin: 0;

                    td {
                        padding: 10px 5px;
                        border-right: 1px solid #ddd;
                        text-align: center;
                        min-width: 4rem;

                        &:first-child {
                            font-weight: 700;
                            min-width: 14rem;
                        }
                    }
                }
            }
        }
    }
}

#shopLayer {
    &[data-dhe="impressum"],
    &[data-dhe="versandinformationen"],
    &[data-dhe="zahlung"],
    &[data-dhe="capgroesse"],
    &[data-dhe="retoure"],
    &[data-dhe="agb"],
    &[data-dhe="widerruf"],
    &[data-dhe="impressum"],
    &[data-dhe="datenschutz"],
    &[data-dhe="newsletter-abmelden"] {
        #FooterJumperBanner {
            display: none;
        }
    }
}

@include breakpoint("m") {
    #shopLayer {
        .service {
            &__content {
                .kontaktformular {
                    .form-group {
                        &.active {
                            label {
                            }
                        }

                        &.nachricht {
                            &.active {
                                label {
                                }
                            }
                        }
                    }

                    .form-group,
                    select {
                        flex: 1 0 75%;
                    }
                }

                .left {
                    .box:last-child {
                    }
                }

                .right {
                }

                .box {
                }
            }
        }
    }
}

@include breakpoint("s") {
    #shopLayer {
        .service {
            &__content {
                flex-direction: column;

                .kontaktformular {
                    .form-group {
                        &.active {
                            label {
                            }
                        }

                        &.nachricht {
                            &.active {
                                label {
                                }
                            }
                        }
                    }

                    .form-group,
                    select {
                    }
                }

                .left {
                    width: 100%;
                    margin: 0;

                    .box:last-child {
                        margin: 1rem 0 0 0;
                    }
                }

                .right {
                    width: 100%;
                }

                .box {
                    margin: 1rem 0;
                }
            }
        }
    }
}
