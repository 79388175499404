$headlines: (
    "default": (
        color: $color-1,
        font-size: 2rem,
        font-weight: 500,
        margin: 10px 0,
        padding: 0
    ),
    "h1": (
        font-size: 2.4rem,
        font-weight: 700
    ),
    "h2": (),
    "h3": (
        font-size: 1.6rem
    ),
    "h4": (
        font-size: 1.6rem
    ),
    "h5": (),
    "h6": ()
);
