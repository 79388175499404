// Abstände
$footer-padding: 2.4rem;

// Farben
$footer-bg: $color-1;
$footer-color: $color-3;
$footer-links-color: $color-4;

// Größen
$footer-link-size: 1.4rem;

#shopFooter {
    #FooterJumperBanner {
        // SEO-Text (2. von unten)
        background: $color-2;
        padding: 4rem;
        font-size: 1.4rem;
        font-weight: 300;

        &:empty {
            display: none;
        }
    }

    .footer {
        // Social, Menüs, Infos (ganz unten)
        background: $color-1;
        padding: 2rem;
        font-size: 1.4rem;

        > div {
            padding: 3rem 0;
        }

        &__social {
            display: flex;
            justify-content: center;

            > a {
                padding: 0 1.5rem;

                > img {
                    max-height: 3rem;
                }
            }
        }

        &__info {
            display: flex;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            border-top: 1px solid rgba(255, 255, 255, 0.12);
            color: $color-2;

            .whatsapp {
                .nummer {
                    font-size: 3rem;
                    padding: 0 0 2rem 0;

                    a {
                        text-decoration: none;
                        color: #ffffff;
                    }
                }
            }

            .newsletter {
                // sobald Newsletter aktiv, die einzelnen divs auf / 3 stellen (Z. 104 aktuell)
                // flex: 0 calc(100% / 2); => flex: 0 calc(100% / 3);
                display: none;

                .email {
                    padding: 0 0 2rem 0;

                    input {
                        background: none;
                        border: none;
                        border-bottom: 1px solid $color-4;
                        padding: 0 0 0.5rem 0;
                        color: $color-4;
                        text-align: center;
                        outline: none;
                        font-size: 1.4rem;
                        width: 220px;
                    }
                }

                button {
                    padding: 0.8rem 1.2rem;
                }
            }

            .zahlungsarten {
                .images {
                    > img {
                        padding: 0 0.5rem;
                        max-height: 3rem;
                    }
                }
            }

            > div {
                text-align: center;
                flex: 1;
                justify-content: center;
                flex-direction: column;
            }
        }

        &__menu {
            color: $color-4;
            display: flex;
            justify-content: space-around;

            > div {
                text-align: center;
                flex: 0 calc(100% / 3);

                li,
                a {
                    color: #b9b9b9;
                    text-decoration: none;
                    padding: 0.1rem 0;
                }
            }

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }
        }

        &__other {
            display: flex;
            justify-content: center;

            > img {
                padding: 0 1.5rem;
                width: 80px;
                height: 80px;
            }
        }

        .headline {
            color: $color-2;
            font-size: 1.8rem;
            margin: 0 0 2rem 0;
        }
    }
}

@include breakpoint("m") {
    #shopFooter {
        #FooterJumperBanner {
            // SEO-Text (2. von unten)
            column-count: 2;
            padding: 2rem;
        }
    }
}

@include breakpoint("s") {
    #shopFooter {
        #FooterJumperBanner {
            // SEO-Text (2. von unten)
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
            }
        }

        .footer {
            // Social, Menüs, Infos (ganz unten)

            > div {
                padding: 0;
            }

            &__social {
                padding: 3rem 0 !important;

                > a {
                }
            }

            &__info {
                flex-direction: column;

                .whatsapp {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
                    padding: 3rem 0;

                    .nummer {
                    }
                }

                .newsletter {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
                    padding: 3rem 0;

                    .email {
                        input {
                        }
                    }

                    button {
                    }
                }

                .zahlungsarten {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
                    padding: 3rem 0;

                    .images {
                        > img {
                        }
                    }
                }

                > div {
                }
            }

            &__menu {
                border-bottom: 1px solid rgba(255, 255, 255, 0.12);
                padding: 3rem 0 !important;

                > div {
                }

                ul {
                }
            }

            &__other {
                padding: 3rem 0 !important;

                > img {
                }
            }

            .headline {
            }
        }
    }
}

@include breakpoint("xs") {
    #shopFooter {
        #FooterJumperBanner {
            // SEO-Text (2. von unten)
            column-count: 1;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
            }
        }

        .footer {
            // Social, Menüs, Infos (ganz unten)

            > div {
            }

            &__social {
                > a {
                    > img {
                    }
                }
            }

            &__info {
                .whatsapp {
                    .nummer {
                    }
                }

                .newsletter {
                    .email {
                        input {
                        }
                    }

                    button {
                    }
                }

                .zahlungsarten {
                    .images {
                        > img {
                        }
                    }
                }

                > div {
                }
            }

            &__menu {
                flex-direction: column;

                .headline {
                    margin: 2rem 0 0.3rem 0;
                }

                > div {
                    li,
                    a {
                    }
                }

                ul {
                }
            }

            &__other {
                > img {
                }
            }

            .headline {
            }
        }
    }
}
