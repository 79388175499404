/* ==========================================================================
10. Bestellübersicht
========================================================================== */

section.fortschritt {
    /*pointer-events:none;*/
    cursor: default;
    margin: 0 0.5rem;
}

section.fortschritt ul {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    background: #d4d4d4;
    margin: 20px 0;
    overflow: hidden;
}

.fortschritt ul li {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    position: relative;
}

.fortschritt ul li.active {
    background: $color-3;
}

section.fortschritt ul li a {
    color: $color-1;
    padding: 6px 20px;
    font-size: 1.6rem;
}

section.fortschritt ul li.active a {
    color: #fff;
    position: relative;
    z-index: 1;
}

.fortschritt li.active.last a {
    border: 4px solid #fff;
    background: #fff;
    color: #999;
    margin-right: -15px;
}

.fortschritt ul li.active + li.active:before {
    content: "";
    height: 50px;
    width: 400%;
    background: $color-3;
    display: block;
    position: absolute;
    right: 80%;
    z-index: 0;
}

.fortschritt ul li:last-child {
    padding-right: 10px;
}

.fortschritt ul li:first-child {
    padding-left: 10px;
}

.fortschritt ul li:first-child {
    padding: 0 5px;
}

.fortschritt ul > li.active.last:first-child a {
    margin: 0 -10px 0 0;
}

.fortschritt ul li.active:after {
    content: "";
    background: url(#{$cdn}/icon_check_w.svg) 0 0 no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 15px;
    right: -11px;
}

.fortschritt ul li.active.last:after {
    background-image: none;
}

.fortschritt li.active.last:last-child a {
    margin-right: -5px;
}

.fortschritt ul li.active.last:after {
    background-image: none;
    background: -moz-linear-gradient(left, rgba(134, 214, 142, 0) 0%, $color-3 100%);
    background: -webkit-linear-gradient(left, rgba(134, 214, 142, 0) 0%, $color-3 100%);
    background: linear-gradient(to right, rgba(134, 214, 142, 0) 0%, $color-3 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0086d68e', endColorstr='#85d081',GradientType=1 );
    height: 50px;
    top: 0;
    right: -10px;
    width: 350%;
    z-index: 0;
}

.wk_lief,
.kundentext,
#shopLayer[data-pp*="login"] #FooterJumperBanner {
    display: none;
}

.tabelle_zk .b,
.wk_zk_div {
    font-size: 1.3rem;
}

.wk_zk_div {
    background: #fff;
}

#kaufabschlussButton {
    margin-top: 2.5rem;
}

figure#nurDerLadePlatzhalter {
    background: #323232;
    max-width: 300px;
    text-align: center;
    color: #fff;
    padding: 20px 30px 20px 10px;
    position: relative;
    margin: 20px 0;

    &:after {
        top: 16px;
        right: 5px;
    }
}

figure.button#nurDerLadePlatzhalter:after {
    content: "";
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 800ms infinite linear;
    display: block;
    position: absolute;
    top: 21px;
    right: 24px;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.zahlart span.box {
    font-weight: 900;
    text-transform: uppercase;
    color: $color-3 !important;
    line-height: 90%;
    font-size: 1.5rem;
}

.zahlart span.box a,
.zahlart span.box b {
    font-weight: initial !important;
    color: initial !important;
    font-size: initial !important;
    text-transform: initial !important;
}

.zahlart span.box b {
    margin-top: 20px;
    display: block;
    color: #999 !important;
}

.zahlart span.box a {
    color: $color-3 !important;
}

a.versandart span {
    color: $color-1;
    margin: 0 0 0 20px;
}

table[style*="#DD2222"],
.PGerror,
.error_vor_absendenbutton {
    border: 1px solid $color-3 !important;
    padding: 18px;
    background: $color-3;
    width: auto;
    margin: 6px auto;
}

table[style*="#DD2222"] *,
.PGerror *,
.error_vor_absendenbutton * {
    color: white;
    font-size: 16px;
    background: none;
}

img.achtung {
    height: 45px;
    margin-right: 20px;
}

.PGerror,
.error_vor_absendenbutton {
    padding-left: 60px;
    position: relative;
    display: block;
    color: white;
}

.PGerror img.achtung {
    margin: 0;
    position: absolute;
    left: 8px;
}

.abschluss .order-info div.versandart span.box a {
    display: flex;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    padding: 5px;
    margin: 10px 20px 0 0;
    min-height: 38px;
}

#abs_ala u {
    display: none;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"] {
    border: 2px solid;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"]:before {
    background: $color-3;
    box-shadow: inset 0 0 0 2px white;
}

.abschluss .order-info div.versandart span.box a:before,
.versandart span.box div.teil label:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #fff;
    border: 1px solid #999;
    cursor: pointer;
    box-sizing: border-box;
}

.abschluss .order-info div.versandart span.box a:nth-of-type(1) {
    margin: 10px 10px 0 0;
}

.versandart {
    padding: 0.5rem;
    box-sizing: border-box;

    img {
        max-width: 118px;
        padding-left: 1rem;
    }
}

.mitte_login.abschluss table {
    border-spacing: 0;
    width: 100%;
}

.mitte_login.abschluss {
    display: block !important;
}

.order-info div.col {
    background: #fff;
    padding: 20px 0px 20px 20px !important;
    display: flex;
    position: relative;
    margin: 0.5rem;
    flex: 1 0 calc(25% - 1rem);
}

.order-info {
    margin: 0 0 20px 0 !important;
}

.order-info div.col > div {
    flex: 1;
}

.order-info div.col:last-child div {
    border: none;
}

.order-info div.col a.edit {
    position: absolute;
    right: 10px;
    bottom: 6px;
    font-size: 1.6rem;
    background: url(#{$cdn}/icon_edit.svg) 0 0 no-repeat;
    padding: 5px 0 5px 25px;
    background-size: 20px;
    line-height: 90%;
}

.order-info div p {
    font-size: 1.8rem;
    color: $color-1;
    margin: 0 0 10px 0;
}

.order-info span.box .teil {
    display: none;
}

.order-info span.box {
    display: block;
    color: $color-1;
    font-size: 1.4rem;
}

.order-info span.box a {
    color: $color-3;
}

.mitVersandart,
.zk_versandartenTD {
    display: none;
}

.order-info form select {
    font-family: "Muli", Arial, sans-serif !important;
    font-size: 0.7rem !important;
    margin: 10px 20px 0 0 !important;
    height: 45px;
    max-width: 243px;
}

.login_o {
    text-align: left;
    font-size: 1.4rem;
}

@include breakpoint("m") {
    .order-info div.col {
        flex: 1 0 calc(100% - 1rem);
        max-width: 100%;
    }
}

@include breakpoint("s") {
    #shopLayer[data-pp*="login"] {
        .fortschritt {
            display: none;
        }
    }
}

/* ==========================================================================
11. Bestellabschluss
========================================================================== */

#bestellabschluss {
    margin: 0 0.5rem;
    font-size: 1.4rem;

    h2,
    h3 {
        font-weight: 700;
    }

    .box {
        background: #fff;
        padding: 2rem;
    }
}

div.PGerror {
    background: $color-3;
    border: 2px solid #fff !important;
    border-radius: 0;
    margin: 0 0.5rem 2rem 0.5rem;
}

.bestellung-einsehen {
    background: #fff;
    font-size: 1.6rem;
    margin: 0 0.5rem 1rem 0.5rem !important;
    padding: 1rem;

    .button2 {
        text-decoration: underline !important;
        cursor: pointer;
    }
}
