// Banner links über Artikeln (Große Headline in linkem Container)
// Farben
$category-headline-color: $color-2;
$category-headline-size: 5rem;

// Abstände
$article-padding: 0 1rem;
$article-margin: 0 0 3rem;

// Farben
$article-bottom-bg: $color-1;
$article-price-color: $color-3;
$article-brandname-color: $color-1;
$article-articlename-color: $color-1;

// Größen
$article-brandname-size: 1.5rem;
$article-brandname-weight: 600;
$article-articlename-size: 0.8rem;
$article-articlename-weight: 500;
$article-price-size: 1.8rem;
$article-price-weight: 600;

#shopMain {
    .list_se {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        > .artikel {
            position: relative;
            background: $color-2;
            width: 260px;
            height: 400px;
            margin: 10px;
            box-sizing: border-box;

            &:hover {
                .se_fav {
                    display: block;
                }

                .se_farben {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin-top: 30px;

                    > .miniSlNav {
                        display: block;
                    }
                }
            }

            > div {
                > a {
                    display: flex;
                    flex-direction: column;
                }

                &.lang {
                    img {
                        max-height: 200px;
                    }
                }

                .se_fav {
                    display: none;

                    &:after {
                        content: " ";
                        display: block;
                        background: map-get($icons, "star-outline");
                        height: 19px;
                        width: 19px;
                        position: absolute;
                        top: 1rem;
                        right: 1rem;
                        cursor: pointer;
                    }

                    &.gemerkt {
                        &:after {
                            background: map-get($icons, "star-full");
                        }
                    }
                }
            }

            .se_name {
                display: flex;
                flex-direction: column;
                padding: 8px;
                height: 4rem;

                .marke {
                    order: -1;
                    font-size: 2rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    max-width: 85%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .artikelname {
                    font-size: 1.4rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 90%;
                }
            }

            .se_farben {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 30px;

                > .miniSlNav {
                    display: none;

                    &.prev,
                    &.next {
                        height: 2rem;
                        width: 2rem;
                        margin: 2px;
                    }

                    &.prev {
                        background: map-get($icons, "arrow-left") no-repeat (50% / contain);
                    }

                    &.next {
                        background: map-get($icons, "arrow-right") no-repeat (50% / contain);
                    }
                }

                > div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;

                    .lief_gr_farbe1_k {
                        height: 1.7rem;
                        width: 1.7rem;
                        margin: 2px;
                        border-radius: 50%;

                        &.active {
                            transform: scale(1.3);
                            margin: 0.5rem;
                        }

                        &.hidden {
                            display: none;
                        }

                        &[data-farbe="5"],
                        &[data-farbe="102"],
                        &[data-farbe="173"],
                        &[data-farbe="219"],
                        &[data-farbe="266"] {
                            border: 1px solid #e6e6e6;
                        }

                        &[data-farb_name="bunt"] {
                            background: map-get($icons, "icon-mehrfarbig") no-repeat !important;
                        }
                    }
                }
            }

            .se_unterteil {
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                height: 60px;
                background: $color-1;
                color: $color-2;
                font-size: 2.8rem;
                width: 100%;
                padding: 0 0 0 8px;
                box-sizing: border-box;

                > .se_preis {
                    display: flex;
                    align-items: center;
                    font-size: 2.4rem;

                    > .se_vk {
                        padding: 0 1rem 0 0;
                    }

                    > .rabatt-box {
                        display: flex;
                        flex-direction: column;

                        strike {
                            text-decoration: line-through;
                            font-size: 1.6rem;
                        }

                        .aa-proz {
                            font-size: 1.6rem;
                            background: map-get($icons, "preis-prozent-bg") no-repeat;
                            text-align: center;
                            order: -1;
                        }
                    }
                }

                .in-wk {
                    position: absolute;
                    bottom: 3rem;
                    right: 1.6rem;
                    width: 6rem;
                    height: 6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1rem;

                    &:after {
                        content: "";
                        display: block;
                        background: map-get($icons, "wk-plus") no-repeat;
                        height: 30px;
                        width: 30px;
                    }

                    img {
                        max-width: unset;
                        width: 28px;
                        height: 28px;
                    }
                }
            }

            figure {
                margin: 0;

                > div {
                    text-align: center;

                    .artikel-img {
                        // img
                        max-width: 200px;
                        height: 180px;
                    }
                }
            }
        } // artikel ende
        #pacerGoOnButton {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 2.5rem 0 0 0;

            > button {
                font-size: 1.6rem;
                padding: 1.2rem 1.8rem;
            }
        }
    }
}

#blaettern_oben #pag_se_all{
    display: none !important;
}

#pag_se {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 2.4rem;
    margin: 4rem 0 0;

    a {
        display: block;
        padding: 10px 40px;

        &:hover svg path {
            fill: $color-3;
        }

        svg path {
            fill: $color-1;
        }
    }

    .pag-left{
        display: flex;

        a {
            display: block;
            padding: 10px 20px !important;
        }
    }
    
    #pag_1.seite_1 {
        display: none;
    }
}

@include breakpoint("xs") {
    #shopMain {
        .startseite {
            .list_se {
                > .artikel {
                    width: calc(50% - 14px);
                }
            }
        }

        .list_se {
            justify-content: center;

            > .artikel {
                height: 270px;
                width: calc(50% - 15px);
                margin: 5px;

                &.diverse-farben {
                    &:after {
                        content: " ";
                        display: block;
                        background: map-get($icons, "diverse-farben");
                        height: 26px;
                        width: 26px;
                        position: absolute;
                        bottom: 5rem;
                        left: 1rem;
                    }
                }

                > div {
                    .se_fav {
                        &:after {
                            top: 0.5rem;
                            right: 0.5rem;
                        }

                        &.gemerkt {
                            &:after {
                            }
                        }
                    }
                }

                .se_name {
                    .marke {
                        font-size: 1.6rem;
                    }

                    .artikelname {
                        font-size: 1.2rem;
                    }
                }

                .se_farben {
                    display: none;
                }

                figure {
                    > div {
                        .artikel-img {
                            max-width: 70%;
                            height: auto;
                        }
                    }
                }

                .se_unterteil {
                    height: 40px;

                    .in-wk {
                        bottom: 2rem;
                        width: 4rem;
                        height: 4rem;
                        right: 0.75rem;
                    }

                    > .se_preis {
                        font-size: 1.8rem;

                        > .se_vk {
                        }

                        > .rabatt-box {
                            strike {
                                font-size: 1.2rem;
                            }

                            .aa-proz {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint("xxs") {
    #shopMain {
        .list_se {
            > .artikel {
                height: 240px;
            }
        }
    }
}
