.tab_se_unten,
#dd_sort,
.anzahl {
    display: none;
}

.banner_oben {
    margin: 2rem 1rem;
    order: 0;

    .banner.oben {
        display: flex;

        &.hersteller {
            > .image {
                background: $color-1;
                display: flex;
                flex: 60%;
                justify-content: center;
                align-items: center;
                padding: 1rem;

                &:after {
                    content: "";
                    display: block;
                    width: 120%;
                    position: absolute;
                    top: -150px;
                    left: -50px;
                    right: 0;
                    box-sizing: border-box;
                    bottom: 0;
                    background: #323232;
                    background-image: var(--background);
                    background-size: 100px;
                    background-blend-mode: multiply;
                    transform: rotate(-15deg);
                    height: 300%;
                }

                img {
                    filter: invert(1) brightness(2);
                    height: 130px;
                }
            }

            > .beschreibung {
                h1 {
                    font-size: 1.8rem !important;
                }
            }
        }

        > .image {
            display: flex;

            //flex: calc(60% + 6rem);
            flex: 1 0 60%;
            background-size: 100px !important;
            background-blend-mode: multiply;
            background-repeat: space;
            position: relative;
            overflow: hidden;

            > img {
                z-index: 1;
            }
        }

        > .titel {
            background: $color-1;
            display: flex;
            flex: 40%;
            justify-content: center;
            align-items: center;

            .headline {
                color: $color-2;
                font-size: 4rem;
                margin: 0;
                font-weight: 700;
            }
        }

        > .beschreibung {
            flex: calc(40%);
            margin: 0 0 0 2rem;
            padding: 3.5rem;
            background: $color-2;
            font-size: 1.4rem;
            box-sizing: border-box;

            h1 {
                margin-top: 0;
                font-size: 2rem;
                color: $color-3;
                font-weight: 700;
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 1.6rem;
                font-weight: 700;
            }

            > p {
                font-size: 1.6rem;
            }

            .weitere {
                text-align: center;
                font-size: 1.6rem;

                a {
                    color: $color-1-1;
                    font-weight: 700;
                }
            }
        }
    }
}

.banner.unten {
    background: $color-2;
    text-align: left;
    column-count: 3;
    column-gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;

    > .beschreibung {
        column-width: 300px;
        column-gap: 2.5rem;
        font-size: 1.4rem;

        h1 {
            font-size: 2.9rem;
            font-weight: 700;
        }

        h2 {
            font-size: 2.5rem;
            line-height: 150%;
            font-weight: 700;
        }

        h3,
        h4,
        h5,
        h6 {
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
        }

        p {
            font-size: 1.6rem;
            line-height: 160%;
        }
    }

    &.baukasten {
        column-count: unset;
        max-width: 990px;
        margin: 0 auto;

        > .beschreibung {
            column-count: 1;
            column-gap: 0;
            column-width: 100%;
        }

        .info-wrapper {
            display: flex;

            .info-inh {
                order: 2;
            }

            .info-text {
                order: 1;
            }
        }

        ul {
            padding: 0 0 0 20px;
        }

        li {
            list-style-type: disc;
            font-weight: 300;
            padding-bottom: 8px;
        }

        &.inh {
            flex-grow: 0;
            width: 234px;
            height: auto;
        }

        .inh li {
            list-style-type: decimal;
        }

        h2 {
            font-size: 2.5rem;
            line-height: 150%;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 1.6rem;
            line-height: 135%;
            column-span: all;
        }

        h4 {
            font-size: 1.5rem;
            line-height: 135%;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 15px;
            line-height: 130%;
            font-weight: 400;

            a,
            b,
            strong {
                font-weight: 700;
                font-size: inherit;
            }
        }

        h1,
        h2,
        h3,
        h4 {
            font-weight: 700;
        }

        &.flex {
            width: 100%;
        }

        //import

        .info-inh {
            max-width: 236px;
            margin: 0 0 0 16px;
            box-sizing: border-box;
            width: 100%;
            gap: 16px;

            div {
                background: #eee;
                padding: 16px;
                margin: 0 0 16px 0;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 135%;
                margin-bottom: 20px;
            }

            ol {
                padding: 0 0 0 15px;
                margin: 0 !important;

                li {
                    list-style: decimal !important;
                    font-size: 1.4rem;
                    font-weight: 400;
                    padding: 0 0 30px 0;

                    a {
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 120%;
                        color: $color-3;
                    }
                }

                ::marker {
                    color: $color-3;
                }
            }
        }

        .info-text {
            max-width: 740px;
            width: 100%;

            p,
            table {
                margin-bottom: 30px;
            }

            p,
            li,
            th,
            td {
                line-height: 160%;
                font-weight: 400;
                font-size: 1.5rem;

                b,
                strong {
                    font-weight: 700;
                    font-size: inherit;
                }
            }

            a {
                font-size: 1.4rem;
                font-weight: 700;

                &.button {
                    color: #fff;
                }
            }

            figure {
                width: 100%;
                margin: 0 0 30px 0;

                + p,
                + h4 {
                    margin-top: 20px;
                }

                &.img-small {
                    min-width: 250px;
                    flex: 1 1;
                }
            }

            ul {
                li {
                    h4 {
                        flex: 1 1 100%;
                    }

                    ul,
                    p {
                        flex: 1;
                        min-width: 200px;
                    }
                }
            }

            table {
                width: 100%;
                border-spacing: 0;
                border-collapse: 0;

                th {
                    background-color: #f2f2f2;
                    border-bottom: 1px solid #333;
                    font-weight: 700;
                }

                th,
                td {
                    padding: 20px 20px;
                }

                tbody {
                    tr:nth-child(even) {
                        background: #f2f2f2;
                    }
                }

                .right {
                    text-align: right;
                }

                .center {
                    text-align: center;
                }

                .left {
                    text-align: left;
                }
            }

            .info-box {
                background: #eee;
                padding: 16px;

                h3 {
                    margin: 10px 0 10px 0;
                }

                + figure {
                    margin-bottom: 0;
                }
            }

            .flex {
                display: flex;
                flex-wrap: wrap;

                h2,
                h3,
                h4 {
                    flex: 1 1 100%;
                }

                * {
                    flex: 1;
                }

                p + figure {
                    margin: 0 0 20px 20px;
                }

                figure + p {
                    margin: 0 20px 20px 0;
                }
            }

            .col-2 {
                display: flex;
                margin: 0 0 30px 0;
                gap: 16px;

                &.img-links {
                    > figure {
                        order: -1;
                    }
                }

                .info-box {
                    min-width: 126px;
                    box-sizing: border-box;
                    max-width: 40%;
                }

                ul {
                    li {
                        font-size: 1.4rem;
                        font-weight: 400;
                        padding: 0 0 15px 0;
                    }
                }
            }
        }
    }
}

@include breakpoint("xl") {
    .banner_oben {
        .banner.oben {
            > .image {
            }

            > .titel {
                flex: calc(60% + 1rem);

                .headline {
                }
            }
        }
    }
}

@include breakpoint("l") {
    .banner_oben {
        .banner.oben {
            > .beschreibung {
                padding: 2rem;

                h1 {
                    font-size: 2rem;
                }

                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-size: 1.6rem;
                    margin: 1.5rem 0 0.5rem 0;
                }

                > p {
                    font-size: 1.6rem;
                }

                .weitere {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

@include breakpoint("m") {
    .banner_oben {
        .banner.oben {
            &.hersteller {
                > .beschreibung {
                    display: none;
                }
            }

            > .image,
            > .titel {
                display: none;
            }

            > .beschreibung {
                margin: 0;
            }
        }
    }
}

@include breakpoint("m") {
    .banner.unten {
        column-count: unset;
    }
}
