@mixin breakpoint($breakpoint, $direction: $media-direction) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
            @content;
        }
    } @else if unitless($breakpoint) {
        $breakpoint: $breakpoint + 0px;

        @media (#{$direction}-width: $breakpoint) {
            @content;
        }
    }
}

/* --------------------------------------------------
   Wie nutze ich dieses Mixin?

    @include breakpoint("xs") {
        background: orangered;
    }

    @include breakpoint("xs", "min") {
        background: yellowgreen;
    }
   -------------------------------------------------- */
