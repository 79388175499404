#shopLayer[data-pp*="login"] {
    .mitte_login {
        display: block !important;
    }

    textarea,
    select,
    input {
        border-radius: 0;
        border: 0;
    }

    .login_a form,
    .login_b form,
    .login_c form {
        background: #f5f5f5;
        margin: 10px;
        flex: 1;
        height: 100%;
    }

    .div_login {
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0 0 0;

        .pass_f {
            color: $color-3;
        }

        .login_o span {
            color: #fff;
            font-size: 2rem;
            padding: 3rem 2rem;
            line-height: 80%;
            background: $color-3;
            display: block;
        }

        input {
            background: none;
            border-bottom: 2px solid #333 !important;
            margin: 0 0 35px 0;
            padding: 0;
            font-weight: 100;
            min-height: 30px;
            min-width: 100%;
            width: 100%;
        }

        input[type="checkbox"] {
            min-width: 0;
        }

        .login_a,
        .login_b {
            flex: 1;
            font-size: 1.4rem;
            margin: 0 0 30px 0;
            border: none;
            padding: 0;
            float: none;
            align-items: stretch;

            form {
                display: flex;

                table {
                    width: 50%;
                }

                tr {
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;

                    td:first-child {
                        color: #666;
                    }

                    td {
                        height: auto !important;
                        width: 100%;
                        text-align: left;

                        label.eingeloggtButton {
                            display: flex;
                            align-items: center;

                            .eingeloggtbleiben {
                                margin: 1px 0 0 10px;
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }

            .login_ueb {
                font-size: 2.1rem;
                color: $color-3 !important;
            }

            button {
                margin: 1.5rem 0 0 0;
                padding: 1rem 6rem;
                font-size: 1.6rem;
            }
        }

        .login_c {
            flex: 0 100%;
            font-size: 1.4rem;
            border: none;
            margin: 0 0 100px 0;
            padding: 0;
            float: none;

            .login_ueb {
                margin: 0 10px -15px 10px;
                display: block;
                background: #f5f5f5;
                padding: 30px 20px 40px 20px;
                font-size: 2.1rem;
                color: #ad1457 !important;
                font-weight: 700;
            }

            > form {
                #schnellog {
                    // ul
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    padding: 0;
                    max-width: 85%;
                    margin: 0 auto;

                    li {
                        width: 50%;
                        padding: 10px;
                        box-sizing: border-box;

                        label {
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: flex-end;

                            span {
                                color: #666;
                            }
                        }

                        input,
                        select {
                            display: flex;
                            min-width: 0;
                            width: 100%;
                        }

                        select {
                            width: 100%;
                            height: 43px;
                            font-weight: 100;
                        }
                    }

                    li#schnellLog_feld5 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        #schnellog_inpu5_1 {
                            min-width: 20rem;
                        }

                        label:nth-of-type(1) {
                            order: 1;
                            width: 60% !important;
                            margin-right: 10%;
                        }

                        label:nth-of-type(2) {
                            order: 2;
                            width: 30% !important;
                        }

                        input:nth-of-type(1) {
                            order: 3;
                            width: 60% !important;
                            margin-right: 10%;
                        }

                        input:nth-of-type(2) {
                            order: 4;
                            width: 30% !important;
                            min-width: 0;
                        }
                    }
                }
            }

            #schnellog_za_table {
                label {
                    display: flex;
                    align-items: center;

                    &:before {
                        content: " ";
                        transition: background 0.5s;
                        position: absolute;
                        transform: translate(0%, -50%);
                        box-sizing: border-box;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border-radius: 20px;
                        top: 50%;
                        left: 15px;
                    }
                }

                .za_dk-div {
                    label {
                        padding: 1rem 1rem 1rem 3rem;
                    }
                }

                .za_dk-div-zart label {
                    padding: 0.5rem;
                    background: #fff;
                    min-height: 60px;
                    box-sizing: border-box;
                }

                button {
                    padding: 1rem 2rem;
                    display: block;
                    margin-top: 2rem;
                }
            }
        }
    }
}

@include breakpoint("m") {
    #shopLayer[data-pp*="login"] {
        .div_login {
            .login_a,
            .login_b {
                width: 100%;

                .login_ueb {
                }

                button {
                }
            }
        }
    }
}

@include breakpoint("s") {
    #shopLayer[data-pp*="login"] {
        .div_login {
            .login_a,
            .login_b {
                flex: 0 0 100%;
                min-width: 0;

                input[type="email"],
                input[type="password"] {
                    width: 100% !important;
                }

                > form {
                    > table {
                        width: 82%;
                    }
                }

                .login_ueb {
                    text-align: left;
                }

                button {
                    min-width: 100%;
                }

                input {
                    min-width: 0;
                }

                input[type="checkbox"] {
                    min-width: 0;
                }
            }
        }

        .div_login {
            .login_c {
                > form {
                    #schnellog {
                        // ul
                        display: block;
                        padding: 0 2rem;

                        li {
                            width: 100%;
                        }

                        input {
                            width: 100%;
                        }

                        li#schnellLog_feld5 {
                            #schnellog_inpu5_1 {
                                min-width: 0;
                            }
                        }
                    }

                    > table {
                        margin: 0 !important;
                    }
                }

                #schnellog_za_table td {
                    width: 100%;
                    display: block;

                    > div {
                        width: 100%;
                        display: block;
                    }

                    .ueb_lief {
                        margin: 0 !important;
                    }
                }
            }

            .ala_tr[style*="display: table-row;"] ul li {
                width: 100%;
            }
        }
    }
}

@include breakpoint("xs") {
    #shopLayer[data-pp*="login"] {
        .login_a,
        .login_b {
            margin: 0;
        }
    }
}

/* hut.de import */

#shopLayer[data-pp*="login"] table#schnellog_za_table {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    max-width: 85%;
    margin: 0 auto !important;

    tr:nth-of-type(2) td:first-child div {
        margin: 0 0 0 -10px;
    }

    tr:nth-of-type(4) > td:first-child {
        height: 50px;

        div {
            margin-top: 20px !important;
        }
    }

    tr:nth-of-type(2),
    tr:nth-of-type(4) {
        display: flex;
        flex-wrap: wrap;

        td .ueb_zahlart,
        td:first-child {
            text-align: left;
            font-size: 2rem;
            padding: 0 0 0 10px;
        }

        td:nth-of-type(2) {
            display: flex;
            flex-wrap: wrap;
        }

        input {
            height: 0;
            margin: 0;
            width: 0 !important;
            min-width: 0 !important;
        }
    }

    tr:last-child td {
        flex-direction: column-reverse;

        button {
            max-width: 200px;
            margin: 30px 0;
        }
    }

    .za_dk-div {
        position: relative;
        overflow: hidden;
        float: none;
        width: 50%;
        margin-top: 6px;
        cursor: pointer;
        padding: 10px;
        box-sizing: border-box;
        display: flex;

        [class^="text_za_dk"] {
            color: $color-3;
            font-size: 1.9rem;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 110%;
            height: auto;
            text-align: left;
            padding-left: 45px;
        }

        [class^="button_za_dk"] {
            background-image: none;
            display: none;
        }

        .ala_label {
            text-align: left;
            line-height: 130%;
            padding: 20px 20px 20px 55px !important;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: start;
            width: 100% !important;
            min-height: 80px;
            position: relative;
            align-items: baseline;
        }

        input {
            height: 0;
            opacity: 0;
            pointer-events: none;
        }
    }
}

.login_c {
    input[type="radio"]:checked + label,
    input[type="radio"] + label {
        background-color: #fff;
        transition: background 0.5s;
        border: none;
        border-radius: 0;
        padding: 20px;
        width: 100%;
        border: 2px solid #fff;
    }

    input[type="radio"]:checked + label {
        border: 2px solid $color-3;
    }

    input[type="radio"]:checked + label:before {
        background: $color-3 !important;
    }
}

.ala_tr[style*="display: table-row;"] {
    display: flex !important;

    #packst_grafik {
        display: none !important;
    }

    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            list-style-type: none;
            padding: 10px;
            width: 50%;
            box-sizing: border-box;

            label:before {
                content: none !important;
            }

            select {
                width: 100%;
                height: 43px;
                font-weight: 100;
            }
        }
    }
}
